import React from "react";

import { useAppSelector } from "app";

import { DrawerVariantsEnum, ITaskById } from "@types";

import { CreateTaskForm } from "./components";
import { EditFeedbackRequestTaskForm } from "./components/EditFeedbackRequestTaskForm";
import { EditTaskForm } from "./components/EditTaskForm";

interface Props {
  initialData?: ITaskById;
  feedback?: boolean;
}

export const CreateTaskContainer = ({ initialData }: Props) => {
  const { drawer } = useAppSelector((state) => state.global);

  if (drawer === DrawerVariantsEnum.editTask) {
    if (initialData) {
      return <EditTaskForm taskData={initialData} />;
    }
  }

  if (drawer === DrawerVariantsEnum.editRequestTask) {
    if (initialData) {
      return <EditFeedbackRequestTaskForm taskData={initialData} />;
    }
  }

  if (drawer !== DrawerVariantsEnum.task) return null;

  return <CreateTaskForm initialData={initialData} />;
};
