import React from "react";
import {
  Control,
  UseFormRegister,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useGetEmployeesInHolding } from "api";
import "ui-kit";

import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { hexToRgba } from "@sbm/fe-utils";
import { FormRadio } from "@sbm/ui-components";
import {
  IDocumentById,
  ISendResolutionForm,
  ResolutionRecipientEmployeeEnums,
} from "@types";

import { RadioButtonsWrapper, Wrapper } from "./styles";

interface Props {
  data?: IDocumentById;
  control: Control<ISendResolutionForm>;
  register: UseFormRegister<ISendResolutionForm>;
  setValue: UseFormSetValue<ISendResolutionForm>;
}

export const SendToResolutionContainer = ({
  data,
  control,
  register,
  setValue,
}: Props) => {
  const { t } = useTranslation("correspondence");
  const theme = useTheme();

  const { recipientEmployeeType, recipient } = useWatch({
    control,
  });

  const { data: employeeList, isLoading: isLoadingResponsible } =
    useGetEmployeesInHolding({
      headOfStructuralUnit: true,
      organizationId: data?.recipients[0].organizationId,
    });

  const handleInputChange = (
    _: React.SyntheticEvent,
    newValue: {
      title: string | null;
      id: number;
      type: "externalSubstitution" | "employee";
    } | null,
    reason: string
  ) => {
    if (reason === "clear") {
      setValue("recipient", undefined);
    }

    if (reason === "selectOption" && newValue) {
      setValue("recipient.id", newValue.id, { shouldDirty: true });
      setValue("recipient.type", newValue.type);
    }
  };

  const employeesOption =
    employeeList?.items?.map((employee) => {
      return {
        title: employee.personalData.longName,
        id: employee.externalSubstitutionId || employee.employeeId,
        type: employee.externalSubstitutionId
          ? "externalSubstitution"
          : "employee",
        position: employee.structuralUnit?.positionName,
      };
    }) || [];

  const options = [
    {
      option: `${t("sendToResolution.recipient_incoming_document")} (${
        data?.recipients[0].personalDataShortName ||
        data?.recipients[0].personalDataLongName
      })`,
      value: ResolutionRecipientEmployeeEnums.CURRENT,
    },
    {
      option: t("sendToResolution.other_addressee"),
      value: ResolutionRecipientEmployeeEnums.OTHER,
    },
  ];

  return (
    <Wrapper>
      <RadioButtonsWrapper>
        <FormRadio
          name="recipientEmployeeType"
          control={control}
          values={options}
          className="radio__buttons"
          flexDirection="column"
          fullWidth={false}
        />
      </RadioButtonsWrapper>

      {recipientEmployeeType === ResolutionRecipientEmployeeEnums.OTHER ? (
        <Stack>
          <Autocomplete
            loading={isLoadingResponsible}
            options={employeesOption}
            onChange={handleInputChange}
            getOptionLabel={(option) => option?.title || ""}
            defaultValue={employeesOption.find(
              (employee) =>
                recipient?.id === employee.id &&
                recipient?.type === employee.type
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                color="secondary"
                size="medium"
                label={t("sendToResolution.full_name")}
                required
              />
            )}
            renderOption={(props, option) => (
              <li {...props}>
                <Stack flexDirection="column" justifyContent="center" gap={1}>
                  <Typography
                    color="text.primary"
                    variant="subtitle1"
                    sx={{ lineHeight: "16px" }}
                  >
                    {option.title}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    color={hexToRgba(theme.palette.text.primary, 0.7)}
                    sx={{ lineHeight: "16px" }}
                  >
                    {option.position}
                  </Typography>
                </Stack>
              </li>
            )}
          />
          <FormControlLabel
            label={t("sendToResolution.notify")}
            sx={{ marginTop: 4 }}
            control={
              <Checkbox {...register("resCheckbox", { required: false })} />
            }
          />
        </Stack>
      ) : null}
    </Wrapper>
  );
};
