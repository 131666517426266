import toast from "react-hot-toast";

import {
  getCorrespondenceIncoming,
  fetchDocumentById,
  fetchCorrespondenceFilesById,
  fetchCorrespondenceLinksById,
  uploadCorrespondenceFile,
  uploadCorrespondenceLinks,
  sendToResolution,
  createIncomingDocument,
  fetchCorrespondenceEvents,
  createOutgoingDocument,
  getFiltersData,
  createServiceNote,
  getTopics,
  fetchDefaultApprovalRoute,
  editCorrespondence,
  editDeleteCorrespondenceAttachedFile,
  deleteCorrespondence,
  addCorrespondenceToMyDocument,
  getMyDocument,
  deleteDocument,
  viewDocument,
  fetchTemplateForBlankDocument,
  sendToResolutionServiceNote,
  transferForSending,
  sendCorrespondenceMessage,
} from "api";
import { AxiosError } from "axios";

import {
  getAPIErrorMessage,
  getFiltersQueryCacheKey,
  SnackbarMessages,
} from "@sbm/fe-utils";
import {
  useQuery,
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import {
  IMeta,
  IIncomingDocument,
  IAttachmentCorrespondenceFile,
  IAttachesTaskLinkBody,
  IAttachmentCorrespondenceLink,
  TDocumentExecutionTrackerNode,
  IDocumentById,
  ResolutionRecipientEmployeeEnums,
  ICorrespondenceFilters,
  CorrespondenceTypeEnum,
  IAvailableTopic,
  TypeResourceEnum,
  IDocumentApprovalRoute,
  ServiceNoteEntity,
  OutgoingDocumentEntity,
  IncomingDocumentEntity,
  FileAttachedToCorrespondenceEditTypeEnum,
  IMyDocument,
  IViewDocument,
  ILetterMessages,
} from "@types";

export const useGetCorrespondenceIncoming = (
  filter: (string | undefined)[] = [],
  sortBy = "",
  search = "",
  options?: Record<string, unknown>
) => {
  const queryKeyFilter = getFiltersQueryCacheKey(filter);

  return useInfiniteQuery<{
    items: IIncomingDocument[];
    meta: IMeta;
  }>(
    ["get_correspondence", search, ...queryKeyFilter, sortBy],
    (params) => getCorrespondenceIncoming(params, filter, sortBy, search),
    {
      ...options,
      cacheTime: 0,
      staleTime: 0,
      retry: false,
      getPreviousPageParam: (params) => {
        const prevPage = params.meta.currentPage - 1;
        return prevPage <= 0 ? 0 : prevPage;
      },
      getNextPageParam: (params) => {
        if (!params?.meta) return 1;

        if (params.meta.currentPage < params.meta.totalPages) {
          return params.meta.currentPage + 1;
        }
      },
    }
  );
};

export const useGetDocumentById = (
  id: string | undefined,
  options: Record<string, unknown>
) => {
  return useQuery<IDocumentById>(
    ["get_document_by_id", id],
    async () => await fetchDocumentById(id),
    {
      ...options,
      retry: false,
    }
  );
};

export const useDeleteDocumentMutation = (
  onSuccess: () => void,
  onError: () => void
) => {
  const queryClient = useQueryClient();

  return useMutation(async (id: number) => await deleteDocument(id), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(["get_my_documents"]);
      toast.success(SnackbarMessages.success);
      onSuccess();
    },
    onError: (e) => {
      const errorMsg = getAPIErrorMessage(e as AxiosError);
      toast.error(errorMsg);
      onError();
    },
  });
};

export const useGetCorrespondenceEvents = (
  id: string | undefined,
  options: Record<string, unknown>
) => {
  return useQuery<TDocumentExecutionTrackerNode[]>(
    ["get_correspondence_events", id],
    async () => await fetchCorrespondenceEvents(id),
    {
      ...options,
      retry: false,
    }
  );
};

export const useGetCorrespondenceFilesById = (
  id: number | undefined,
  options?: Record<string, unknown>
) => {
  return useQuery<{ id: number; files: IAttachmentCorrespondenceFile[] }>(
    ["get_correspondence_files_by_id", id],
    async () => await fetchCorrespondenceFilesById(id),
    { ...options }
  );
};

export const useCorrespondenceLinksById = (id: number | undefined) => {
  return useQuery<{ id: number; links: IAttachmentCorrespondenceLink[] }>(
    ["get_correspondence_links_by_id", id],
    async () => await fetchCorrespondenceLinksById(id)
  );
};

export const useUploadFileToCorrespondenceMutation = (
  onSuccess: () => void,
  onError: () => void
) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (body: { requestBody: FormData; correspondenceId: number }) => {
      await uploadCorrespondenceFile(body);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["get_correspondence_files_by_id"]);
        onSuccess();
        toast.success(SnackbarMessages.success);
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        onError();
        toast.error(errorMsg);
      },
    }
  );
};

export const useUploadLinkToCorrespondenceMutation = (
  onSuccess: () => void,
  onError: () => void
) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (body: {
      requestBody: IAttachesTaskLinkBody;
      correspondenceId: number;
    }) => {
      await uploadCorrespondenceLinks(body);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["get_correspondence_links_by_id"]);
        onSuccess();
        toast.success(SnackbarMessages.success);
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        onError();
        toast.error(errorMsg);
      },
    }
  );
};

export const useSendToResolutionMutation = (
  onSuccess: () => void,
  onError: () => void
) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (body: {
      correspondenceId: number;
      recipient?: {
        id?: number;
        type?: "employee" | "externalSubstitution";
      };
      recipientEmployeeType: ResolutionRecipientEmployeeEnums;
      notifyRecipient?: boolean;
    }) => {
      await sendToResolution(body);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["get_document_by_id"]);
        onSuccess();
        toast.success(SnackbarMessages.success);
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        onError();
        toast.error(errorMsg);
      },
    }
  );
};

export const useSendToResolutionMutationServiceNote = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (body: { correspondenceId: number }) => {
      await sendToResolutionServiceNote(body);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["get_document_by_id"]);
        toast.success(SnackbarMessages.success);
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
      },
    }
  );
};

export const useTransferForSending = (onSuccess: () => void) => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({
      body,
    }: {
      body: { correspondenceId: number; comment?: string };
    }) => await transferForSending(body),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["get_document_by_id"]);
        onSuccess();
        toast.success(SnackbarMessages.success);
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
      },
    }
  );
};

export const useCreateIncomingDocumentMutation = (
  onSuccess: () => void,
  newVersion?: boolean
) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (body: FormData) => {
      await createIncomingDocument(body, newVersion);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["get_correspondence"]);
        toast.success(SnackbarMessages.success);
        onSuccess();
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
      },
    }
  );
};

export const useCreateOutgoingDocumentMutation = (onSuccess: () => void) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (body: FormData) => {
      await createOutgoingDocument(body);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["get_correspondence"]);
        toast.success(SnackbarMessages.success);
        onSuccess();
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
      },
    }
  );
};

export const useGetFiltersData = (
  correspondenceTypeEnum: CorrespondenceTypeEnum
) => {
  return useQuery<ICorrespondenceFilters>(
    ["get_correspondence_filters"],
    async () => await getFiltersData(correspondenceTypeEnum)
  );
};

export const useGetTopics = (
  organizationId: number | undefined,
  typeResourceId: TypeResourceEnum,
  search = ""
) => {
  return useQuery<{ items: IAvailableTopic[]; meta: IMeta }>(
    ["get_available_topic", organizationId, typeResourceId, search],
    async () => await getTopics({ organizationId, typeResourceId, search })
  );
};

export const useGetTemplateForBlankDocument = () => {
  return useQuery<
    { id: number; nameOfBlankForUser: string; templateLink: string }[]
  >(["get_template_for_blank_document"], fetchTemplateForBlankDocument);
};

export const useGetDefaultApprovalRoute = (
  correspondenceId: number | undefined
) => {
  return useQuery<{ approvers: IDocumentApprovalRoute[] }>(
    ["get_default_approval_route", correspondenceId],
    async () => await fetchDefaultApprovalRoute(correspondenceId)
  );
};

export const useGetMyDocuments = (
  filter: (string | undefined)[] = [],
  sortBy = "",
  search = "",
  options?: Record<string, unknown>
) => {
  const queryKeyFilter = getFiltersQueryCacheKey(filter);

  return useInfiniteQuery<{ items: IMyDocument[]; meta: IMeta }>(
    ["get_my_documents", search, ...queryKeyFilter, sortBy],
    (params) => getMyDocument(params, filter, sortBy, search),
    {
      ...options,
      retry: false,
      cacheTime: 10000,
      staleTime: 10000,
      getPreviousPageParam: (params) => {
        const prevPage = params.meta.currentPage - 1;
        return prevPage <= 0 ? 0 : prevPage;
      },
      getNextPageParam: (params) => {
        if (!params?.meta) return 1;

        if (params.meta.currentPage < params.meta.totalPages) {
          return params.meta.currentPage + 1;
        }
      },
    }
  );
};

export const useCreateServiceNoteMutation = (onSuccess: () => void) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (body: FormData) => {
      await createServiceNote(body);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["get_correspondence"]);
        toast.success(SnackbarMessages.success);
        onSuccess();
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
      },
    }
  );
};

export const useEditCorrespondenceMutation = (
  onSuccess: () => void,
  newVersion?: boolean
) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (
      payload:
        | ServiceNoteEntity
        | OutgoingDocumentEntity
        | IncomingDocumentEntity
    ) => {
      await editCorrespondence(payload, newVersion);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["get_correspondence"]);
        await queryClient.invalidateQueries(["get_document_by_id"]);
        toast.success(SnackbarMessages.success);
        onSuccess();
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
      },
    }
  );
};

export const useDeleteCorrespondenceMutation = (onSuccess: () => void) => {
  const queryClient = useQueryClient();

  return useMutation(async (id: number) => await deleteCorrespondence(id), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(["get_correspondence"]);
      toast.success(SnackbarMessages.success);
      onSuccess();
    },
    onError: (e) => {
      const errorMsg = getAPIErrorMessage(e as AxiosError);
      toast.error(errorMsg);
    },
  });
};

export const useAddedCorrespondenceToMyDocument = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (id: number) => await addCorrespondenceToMyDocument(id),
    {
      onSuccess: async () => {
        toast.success(SnackbarMessages.success);
      },
      onError: (error: AxiosError) => {
        const errorMsg = getAPIErrorMessage(error);
        if (errorMsg) {
          toast.error(errorMsg);
        }
      },
      onSettled: async () => {
        await queryClient.invalidateQueries(["get_correspondence"]);
        await queryClient.invalidateQueries(["get_contracts_list"]);
      },
    }
  );
};

export const useEditDeleteCorrespondenceAttachedFileMutation = (
  onSuccess: () => void,
  onError: () => void
) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (body: {
      requestBody: FormData;
      correspondenceId: number;
      fileId?: number;
      type: FileAttachedToCorrespondenceEditTypeEnum;
    }) => {
      if (body.fileId) {
        await editDeleteCorrespondenceAttachedFile(
          body.requestBody,
          body.correspondenceId,
          FileAttachedToCorrespondenceEditTypeEnum.delete,
          body.fileId
        );
      } else {
        await editDeleteCorrespondenceAttachedFile(
          body.requestBody,
          body.correspondenceId,
          FileAttachedToCorrespondenceEditTypeEnum.edit
        );
      }
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["get_correspondence_files_by_id"]);
        toast.success(SnackbarMessages.success);
        onSuccess();
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
        onError();
      },
    }
  );
};

export const useViewDocumentMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (body: IViewDocument) => {
      await viewDocument(body);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["get_my_documents"]);
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
      },
    }
  );
};

export const useSendCorrespondenceMessageMutation = (onSuccess: () => void) => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({
      correspondenceId,
      body,
    }: {
      correspondenceId: number;
      body: { messages: (Partial<ILetterMessages> | null)[] };
    }) => {
      await sendCorrespondenceMessage(correspondenceId, body);
    },
    {
      onSuccess: async () => {
        toast.success(SnackbarMessages.success);
        await queryClient.invalidateQueries(["get_document_by_id"]);
        onSuccess();
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
      },
    }
  );
};
