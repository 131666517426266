import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useGetTopics } from "api";
import { useDebounce } from "app";

import {
  Typography,
  Card,
  CardContent,
  Autocomplete,
  TextField,
} from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { Input } from "@sbm/ui-components";
import { TypeResourceEnum } from "@types";

import { DetailsStepProps } from "../DetailsStep";

interface TopicOptionType {
  title: string;
  value: string;
  inputValue?: string;
}

const filter = createFilterOptions<TopicOptionType>();

export const DetailsStepDocumentContent: React.FC<
  Pick<
    DetailsStepProps,
    "control" | "setValue" | "register" | "errors" | "getValues"
  >
> = ({ setValue, register, getValues }) => {
  const { t } = useTranslation("correspondence");
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search);

  const organizationId = useMemo(() => {
    return getValues("senderOrganizationId");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues("senderOrganizationId")]);

  const { data: topicsList } = useGetTopics(
    organizationId,
    TypeResourceEnum.OutgoingMessage,
    debouncedSearch
  );

  const senderOrganizationId = getValues("senderOrganizationId");

  useEffect(() => {
    setSearch("");
  }, [senderOrganizationId]);

  const list = topicsList?.items;

  const textTopicOptions: TopicOptionType[] = list
    ? Object.values(list)
        .filter(
          (option, index, self) =>
            index === self.findIndex((o) => t(o.text) === t(option.text))
        )
        .map((option) => ({
          title: t(option.text),
          value: String(option.id),
        }))
    : [];

  const handleTopicChange = (
    _: React.SyntheticEvent,
    value: TopicOptionType | string | null
  ) => {
    if (!value) {
      setValue("topicForTheCorrespondence", undefined);
      return;
    }

    if (typeof value === "string") {
      setValue("textForTopicId", undefined);
      setValue("topicForTheCorrespondence", value, { shouldDirty: true });
    } else if (value.inputValue) {
      setValue("textForTopicId", undefined);
      setValue("topicForTheCorrespondence", value.inputValue, {
        shouldDirty: true,
      });
    } else {
      setValue("textForTopicId", +value.value, { shouldDirty: true });
      setValue("topicForTheCorrespondence", undefined);
    }
  };

  const topicDefaultValue: TopicOptionType | string | null | undefined =
    useMemo(() => {
      const topicForTheCorrespondence = getValues("topicForTheCorrespondence");
      const textForTopicId = getValues("textForTopicId");

      if (topicForTheCorrespondence) {
        return topicForTheCorrespondence;
      }

      if (textForTopicId && list) {
        const currentItemFromList = list.find((l) => l.id === textForTopicId);

        if (currentItemFromList) {
          return {
            title: currentItemFromList.text || "",
            value: String(currentItemFromList.id),
          };
        }
      }

      return null;
    }, [getValues, list]);

  return (
    <Card>
      <CardContent sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
        <Typography variant="h9_semiBold" color="text.disabled" sx={{ mb: 2 }}>
          {t("createLetterDrawer.topic_area.title").toUpperCase()}
        </Typography>

        <Autocomplete<TopicOptionType, false, false, true> // <T, Multiple, DisableClearable, FreeSolo>
          key={senderOrganizationId}
          disablePortal
          freeSolo
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            const { inputValue } = params;
            const isExisting = options.some(
              (option) => inputValue === option.title
            );
            if (inputValue !== "" && !isExisting) {
              filtered.push({
                inputValue,
                title: `${t("add")} "${inputValue}"`,
                value: "",
              });
            }

            return filtered;
          }}
          options={textTopicOptions}
          onChange={handleTopicChange}
          onInputChange={(_, newInputValue) => {
            setValue("topicForTheCorrespondence", newInputValue, {
              shouldDirty: true,
            });
            setValue("textForTopicId", undefined, { shouldDirty: true });
            setSearch(newInputValue);
          }}
          getOptionLabel={(option) => {
            if (typeof option === "string") {
              return option;
            }
            if (option.inputValue) {
              return option.inputValue;
            }
            return option.title ?? "";
          }}
          renderOption={(props, option) => <li {...props}>{option.title}</li>}
          value={topicDefaultValue}
          renderInput={(params) => (
            <TextField
              {...params}
              required
              variant="outlined"
              color="secondary"
              size="medium"
              label={t("createLetterDrawer.topic_area.topic_label")}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              inputProps={{ ...params.inputProps, maxLength: 500 }}
            />
          )}
        />

        <Input
          label={t("createLetterDrawer.topic_area.context_label")}
          variant="outlined"
          color="secondary"
          size="medium"
          maxLength={1000}
          {...register("contextForTheCorrespondence")}
        />
      </CardContent>
    </Card>
  );
};
