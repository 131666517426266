import React from "react";
import { Control, UseFormRegister, UseFormSetValue } from "react-hook-form";

import { ILetterMessages, IMailData } from "@types";

import { MethodStepItem } from "../MethodStepItem";

export interface MethodStepProps {
  setValue: UseFormSetValue<IMailData>;
  register: UseFormRegister<IMailData>;
  messages?: ILetterMessages[];
  control: Control<IMailData>;
}

export const MethodStep: React.FC<MethodStepProps> = ({
  setValue,
  register,
  control,
  messages,
}) => {
  return (
    <React.Fragment>
      {messages?.map((message, index) => {
        return (
          <MethodStepItem
            key={index}
            setValue={setValue}
            register={register}
            messages={messages}
            control={control}
            current={message}
            index={index}
          />
        );
      })}
    </React.Fragment>
  );
};
