import React from "react";
import { useTranslation } from "react-i18next";

import {
  Card,
  CardContent,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
  useTheme,
} from "@mui/material";
import { FormRadio } from "@sbm/ui-components";
import { ILetterMessages, MethodOfSendingEnum } from "@types";

import { MethodStepProps } from "../MethodStep";

export const MethodStepItem: React.FC<
  MethodStepProps & { index: number; current: ILetterMessages }
> = ({ setValue, register, control, messages, current, index }) => {
  const theme = useTheme();
  const { t } = useTranslation("correspondence");

  if (!messages?.length) {
    return <></>;
  }

  const isSendFromSystemDisabled = Boolean(
    !messages[index].canSendViaOrganizationEmail &&
      !messages[index].employeeId &&
      !messages[index].externalSubstitutionId
  );

  const systemOptions = [
    {
      option: t(MethodOfSendingEnum.viaOrganizationEmail),
      value: MethodOfSendingEnum.viaOrganizationEmail,
      disabled: !messages[index].canSendViaOrganizationEmail,
    },
    {
      option: t(MethodOfSendingEnum.viaApplication),
      value: MethodOfSendingEnum.viaApplication,
      disabled:
        !messages[index].employeeId && !messages[index].externalSubstitutionId,
    },
  ];

  const serviceOptions = [
    {
      option: t(MethodOfSendingEnum.byEmail),
      value: MethodOfSendingEnum.byEmail,
    },
    {
      option: t(MethodOfSendingEnum.byRussianPost),
      value: MethodOfSendingEnum.byRussianPost,
    },
    {
      option: t(MethodOfSendingEnum.viaCourierDeliveryService),
      value: MethodOfSendingEnum.viaCourierDeliveryService,
    },
    {
      option: t(MethodOfSendingEnum.byARepresentativeOfTheSendingOrganisation),
      value: MethodOfSendingEnum.byARepresentativeOfTheSendingOrganisation,
    },
  ];

  const onChangeListener = (type: any) => {
    setValue(`messages.${index}.file`, null);
    setValue(`messages.${index}.sendDate`, undefined);
    setValue(`messages.${index}.senderEmailId`, undefined);
    setValue(`messages.${index}.recipientEmailId`, undefined);
    setValue(`messages.${index}.courierServiceName`, undefined);
    setValue(`messages.${index}.note`, undefined);
  };

  return (
    <Stack mt={8}>
      <Stack mb={4} direction={"column"}>
        <Typography variant={"body1"} fontWeight={700}>
          {current.personalDataLongName}
        </Typography>
        <Typography color={theme.palette.text.secondary} fontWeight={600}>
          {current.briefTextOrganizationName}
        </Typography>
      </Stack>
      <Card>
        <CardContent>
          <Typography
            variant={"h9_semiBold"}
            color={theme.palette.text.disabled}
          >
            {t("sending_method").toUpperCase()}
          </Typography>
          <Stack mt={6}>
            <FormControlLabel
              label={t("send_from_system")}
              disabled={isSendFromSystemDisabled}
              control={
                <Switch
                  color="secondary"
                  defaultChecked={current.sendFromSystem}
                  {...register(`messages.${index}.sendFromSystem`)}
                />
              }
            />
            {messages[index].sendFromSystem && (
              <Stack pl={12}>
                <FormRadio
                  name={`messages.${index}.methodOfSystem`}
                  size="medium"
                  control={control}
                  flexDirection="column"
                  alignItems="flex-start"
                  values={systemOptions}
                />
              </Stack>
            )}
            <FormControlLabel
              sx={{ mt: 4 }}
              label={t("sending_via_external_services")}
              control={
                <Switch
                  color="secondary"
                  defaultChecked={current.sendFromService}
                  {...register(`messages.${index}.sendFromService`)}
                />
              }
            />
            {messages[index].sendFromService && (
              <Stack pl={12}>
                <FormRadio
                  name={`messages.${index}.methodOfSending`}
                  size="medium"
                  control={control}
                  flexDirection="column"
                  alignItems="flex-start"
                  values={serviceOptions}
                  onChangeListener={onChangeListener}
                />
              </Stack>
            )}
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
};
