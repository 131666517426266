import {
  FileStatusEnum,
  IPersonalData,
  IPosition,
  IStructuralUnit,
} from "@types";

import { IAttachLink } from "./messages";
import { StatusForAuthorEnum, TypeEventEnum } from "./tasks";

export interface IIncomingDocument {
  id: number;
  constructionComplex: string[];
  correspondent: string | null;
  correspondentName: string | null;
  createdAt: Date | string;
  creationDate: Date | string;
  dateOfRegistrationCounterparty: Date | string;
  documentName: string;
  documentStatus: string;
  documentTopic: string;
  documentType: string;
  internalNumber: number | string | null;
  internalRegistrationNumber: string;
  note: string;
  recipient: string;
  recipientName: string | null;
  regNumberForCounterparty: string;
  registrationDate: Date | string;
  updatedAt: Date | string;
  correspondents?: IIncomingCorrespondent[] | IServiceNotesCorrespondent[];
}

export interface IIncomingCorrespondent {
  correspondentName?: string;
  dateOfRegistrationCounterparty: string;
  organizationName: string;
  regNumberForCounterparty: number | null;
}

export interface IServiceNotesCorrespondent {
  correspondentName: string;
  structuralUnitName: string;
  correspondentPosition: IPosition | string;
  structuralUnitType?: string;
}

export interface ILinkedEntities {
  typeOfTheDocument: string;
  messageId: number | null;
  taskId: number | null;
  regularTaskId: number | null;
  contractId: number;
  supplementaryAgreementId: number | null;
  correspondenceId: number | null;
  oadId: number | null;
  serviceNoteId: null;
  workplanId: number | null;
  minutesInternalMeetingId: number | null;
  powerOfAttorneyId: number | null;
  manualId: number | null;
  externalLinkUrl?: string;
}

export enum CorrespondenceSubtypeEnum {
  letter = "letter",
  juridical = "juridical",
  accounting = "accounting",
}

export enum CorrespondenceTypeEnum {
  incomingDocument = "incoming_document",
  outgoingDocument = "outgoing_document",
  serviceNote = "service_note",
}

export enum StorageOrderForOriginalEnum {
  general = "general",
  special = "special",
}

export enum FileAttachedToCorrespondenceTypeOfAttachmentEnum {
  mainDocument = "main_document",
  appendix = "appendix",
  deliveryConfirmation = "delivery_confirmation",
  supportingFile = "supporting_file",
}
export enum LinkAttachedToCorrespondenceTypeOfAttachmentEnum {
  auxiliaryDocument = "auxiliary_document",
  initiatingDocument = "initiating_document",
  responseToIncomingDocument = "response_to_incoming_document",
  responseToOutgoingDocument = "response_to_outgoing_document",
  responseToServiceNote = "response_to_service_note",
}

export enum DeliveryMethodEnum {
  byEmail = "by_email",
  byRussianPost = "by_russian_post",
  viaCourierDeliveryService = "via_courier_delivery_service",
  byARepresentativeOfTheSendingOrganisation = "by_a_representative_of_the_sending_organisation",
}

export enum FileAttachedToCorrespondenceFileStatusEnum {
  draftDocument = "draft_document",
  signedDocument = "signed_document",
}

export enum FileAttachedToCorrespondenceEditTypeEnum {
  edit = "edit",
  delete = "delete",
}

export interface IncomingDocumentEntity {
  id?: number;
  correspondenceSubtype: CorrespondenceSubtypeEnum;
  regNumberForCounterparty: string;
  dateOfRegistration?: Date | string;
  initiatingCorrespondenceId?: number;
  initiatingDocumentOutTheSystem?: string;
  initiatingDocumentRegistrationDate?: Date | string;
  replyToOutgoingLetter?: boolean;
  confidentially: boolean;
  selectConstructionComplexes?: boolean;
  constructionComplexes?: number[];
  legalEntity?: boolean;
  senderOrganizationId?: number;
  senderEmployeePosition?: string;
  recipientOrganizationId?: number;
  mainActorEmployeePosition?: string;
  topicForTheCorrespondence?: string;
  textForTopicId?: number;
  contextForTheCorrespondence?: string;
  deliveryMethod?: DeliveryMethodEnum;
  storageOrderForOriginal?: StorageOrderForOriginalEnum;
  emailId?: number;
  creatorPosition?: "employee" | "external";
  isCreaterExternalSubstitution?: boolean;
  links?: IAttachLink[];
  files?: File[];
  typeOfAttachment?: FileAttachedToCorrespondenceTypeOfAttachmentEnum[];
  sender?: {
    id: number;
    type: TypeOfCorrespondenceEnum;
  };
  recipient?: {
    id: number;
    type: TypeOfCorrespondenceEnum;
  };
}

export type TOutgoingDocumentRecipientEntity = {
  recipientOrganizationId?: number;
  legalEntity?: boolean;
  recipientEmployeePosition?: string;
  // New fields, olds can be deleted after PO review
  type?: TypeOfCorrespondenceEnum;
  id?: number;
};

export type TServiceNoteRecipientEntity = {
  recipientEmployeeId?: number;
  recipientExternalSubstitutionId?: number;
  recipientStructuralUnitId?: number;
  recipientEmployeePosition?: string;
};

export enum TypeOfCorrespondenceInHoldingEnum {
  employee = "employee",
  externalSubstitution = "externalSubstitution",
}

export enum TypeOfCorrespondenceEnum {
  employee = "employee",
  externalSubstitution = "externalSubstitution",
  externalOrganizationStaff = "externalOrganizationStaff",
  individual = "individual",
}

export interface OutgoingDocumentEntity {
  id?: number;
  correspondenceSubtype: CorrespondenceSubtypeEnum;
  initiatingCorrespondenceId?: number;
  initiatingDocumentOutTheSystem?: string;
  initiatingDocumentRegistrationDate?: Date | string;
  replyToOutgoingLetter?: boolean;
  confidentially: boolean;
  controlTheResponse?: boolean;
  legalEntity?: boolean;
  expectedDateOfTheResponse?: Date | string;
  selectConstructionComplexes?: boolean;
  constructionComplexes?: number[];
  senderEmployeePosition?: string;
  senderOrganizationId?: number;
  topicForTheCorrespondence?: string;
  contextForTheCorrespondence?: string;
  textForTopicId?: number;
  creatorPosition?: "employee" | "external";
  isCreaterExternalSubstitution?: boolean;
  recipientOrganizationId?: number;
  links?: IAttachLink[];
  files?: File[];
  fileStatusAttachment?: FileAttachedToCorrespondenceFileStatusEnum[];
  typeOfAttachment?: FileAttachedToCorrespondenceTypeOfAttachmentEnum[];
  recipients: TOutgoingDocumentRecipientEntity[];
  personalDataId?: number;
  responsible?: {
    id?: number;
    type?: TypeOfCorrespondenceInHoldingEnum;
  };
  sender?: {
    id?: number;
    type?: TypeOfCorrespondenceInHoldingEnum;
  };
}

export interface ServiceNoteEntity {
  id?: number;
  initiatingCorrespondenceId?: number;
  correspondenceSubtype?: CorrespondenceSubtypeEnum;
  initiatingDocumentOutTheSystem?: string;
  initiatingDocumentRegistrationDate?: Date | string;
  replyToOutgoingLetter?: boolean;
  confidentially: boolean;
  selectConstructionComplexes?: boolean;
  constructionComplexes?: number[];
  senderEmployeePosition?: string;
  senderEmployeeId?: number;
  senderExternalSubstitutionId?: number;
  senderOrganizationId?: number;
  senderStructuralUnitId?: number;
  textForTopicId?: number;
  controlTheResponse?: boolean;
  expectedDateOfTheResponse?: Date | string;
  topicForTheCorrespondence?: string;
  contextForTheCorrespondence?: string;
  creatorPosition?: "employee" | "external";
  isCreaterExternalSubstitution?: boolean;
  links?: IAttachLink[];
  files?: File[];
  fileStatusAttachment?: FileAttachedToCorrespondenceFileStatusEnum[];
  typeOfAttachment?: FileAttachedToCorrespondenceTypeOfAttachmentEnum[];
  recipients: TServiceNoteRecipientEntity[];
}

export enum StandartTextForTopicEnum {
  certification = "certification",
  wasteRemovalAndUtilisation = "waste_removal_and_utilisation",
  fieldCommission = "field_commission",
  lettersOfGuarantee = "letters_of_guarantee",
  contractConclusion = "contract_conclusion",
  remarksOnThePrescription = "remarks_on_the_prescription",
  prohibitionOfWork = "prohibition_of_work",
  requestForContractualDocuments = "request_for_contractual_documents",
  requestForDailyInformationOnPeopleAndEquipment = "request_for_daily_information_on_people_and_equipment",
  requestForInformationOnTheNeedForLimits = "request_for_information_on_the_need_for_limits",
  requestForInformationOnMaterialRequirements = "request_for_information_on_material_requirements",
  requestForInformationOnLabourRequirements = "request_for_information_on_labour_requirements",
  requestForPlansAndSchedules = "request_for_plans_and_schedules",
  adjustmentOfLimits = "adjustment_of_limits",
  priceMonitoring = "price_monitoring",
  expectedFulfilment = "expected_fulfilment",
  familiarisationWithRegulationsBriefings = "familiarisation_with_regulations_briefings",
  siteInspections = "site_inspections",
  provisionOfTechnicalSupervision = "provision_of_technical_supervision",
  provisionOfTransportSchemes = "provision_of_transport_schemes",
  conductingBriefings = "conducting_briefings",
  carryingOutInspections = "carrying_out_inspections",
  siteTurnaround = "site_turnaround",
  constructionAuthorisation = "construction_authorisation",
  approvalOfTenDaySchedules = "approval_of_ten_day_schedules",
  approvalOfPdp = "approval_of_pdp",
  lettersOfTransmittalOfDocuments = "letters_of_transmittal_of_documents",
  technologicalWindows = "technological_windows",
  eliminationOfRemarksAndViolations = "elimination_of_remarks_and_violations",
}

export enum StatusForCorrespondenceEnum {
  draft = "draft",
  onResolution = "on_resolution",
  onExecution = "on_execution",
  completedWithoutResolutions = "completed_without_resolutions",
  executed = "executed",
  toBeAgreed = "to_be_agreed",
  atSigning = "at_signing",
  dispatch = "dispatch",
  sentToRecipient = "sent_to_recipient",
  canceled = "canceled",
  inProgress = "in_progress",
  completed = "completed",
}

export enum DocumentFieldsEnum {
  registrationDate = "registrationDate",
  documentType = "documentType",
  dateOfRegistrationCounterparty = "dateOfRegistrationCounterparty",
  documentStatus = "documentStatus",
  createdAt = "createdAt",
  constructionComplex = "constructionComplex",
  sender = "sender",
  senderName = "senderName",
  organizationNames = "organizationNames",
  correspondentNames = "correspondentNames",
  documentTopic = "documentTopic",
  note = "note",
  senderStructuralUnitName = "senderStructuralUnitName",
  senderPosition = "senderPosition",
  correspondentStructuralUnitNames = "correspondentStructuralUnitNames",
  correspondentPositions = "correspondentPositions",
}

export enum StructuralUnitTypesForCorrespondenceEnum {
  mainGoverningBody = "main_govering_body",
  goveringBody = "govering_body",
  functionalBlock = "functional_block",
  department = "department",
  division = "division",
  sector = "sector",
  directoriateForManufactoring = "directoriate_for_manufactoring",
  unitForManufactoring = "unit_for_manufactoring",
  groupForManufactoring = "group_for_manufactoring",
}

export interface IRecipients {
  organizationId: number;
  id: number;
  type: TypeOfCorrespondenceEnum;
  briefTextOrganizationName: string;
  position: string;
  personalDataLongName: string;
  personalDataId?: number;
  externalSubstitutionId?: number;
  externalOrganizationStaffId?: number;
  personalDataShortName?: number | null;
  legalEntity?: boolean;
  employeeId?: number;
  structuralUnit?: Partial<IStructuralUnit>;
  counterpartyId?: number;
}

export interface IDocumentById {
  id: number;
  attachmentsCount?: number;
  hasObjectInternalApprovalProcess: boolean;
  deliveryMethod?: DeliveryMethodEnum;
  sender: {
    id?: number;
    legalEntity: boolean;
    organizationId?: number;
    briefTextOrganizationName?: string;
    position?: string;
    personalDataLongName: string;
    employeeId?: number;
    externalOrganizationStaffId?: number;
    personalDataId?: number;
    externalSubstitutionId?: number;
    personalDataShortName?: number | null;
    type?: TypeOfCorrespondenceInHoldingEnum | TypeOfCorrespondenceEnum;
    structuralUnit?: Partial<{
      id: number;
      nameOfStructuralUnit: string;
      structuralUnitType: StructuralUnitTypesForCorrespondenceEnum;
    }>;
  };
  recipients: IRecipients[];
  internalRegistrationNumber: string;
  initiatingCorrespondenceRegNumber: string;
  initiatingCorrespondenceDate: Date;
  initiatingCorrespondenceId?: number;
  expectedDateOfTheResponse?: Date;
  controlTheResponse?: boolean;
  initiatingDocumentOutTheSystem?: string;
  initiatingDocumentRegistrationDate?: Date | string;
  registrationDate: Date;
  correspondenceSubtype: CorrespondenceSubtypeEnum;
  statusForCorrespondence: StatusForCorrespondenceEnum;
  storageOrderForOriginal: StorageOrderForOriginalEnum;
  numberAtCounterparty: string;
  dateOfRegistration: Date;
  confidentially: true;
  contextForTheCorrespondence: string;
  topicForTheCorrespondence?: string;
  textForTopicId?: number;
  textForTopic?: string;
  createdAt?: Date;
  hasSignedMainDocument: boolean;
  constructionComplexes: {
    id: number;
    briefTextConstructionComplexName: string;
  }[];
  registeredByLongName: string;
  registeredByPersonalDataId: number;
  responsibleEmployeeId?: number;
  responsibleExternalSubstitutionId?: number;
  emailId?: number;
  correspondenceType?: CorrespondenceTypeEnum;
  canSendViaOrganizationEmail?: boolean;
  isResponsibleForSending?: boolean;
  styleForStatus?: string;
  responsible?: {
    id: number;
    type: TypeOfCorrespondenceInHoldingEnum;
  };
}

export type IAttachmentCorrespondenceFile = {
  id: number;
  convertedPdfFileName: string | null;
  fileStatus: FileStatusEnum | null;
  typeOfAttachment: FileAttachedToCorrespondenceTypeOfAttachmentEnum;
  fileFormat: string;
  fileNameForUsers: string;
  fileNameForSystem: string;
  fileUploadDate: Date;
  additionalInformation: string | null;
  fileAttachedBy: {
    id: number;
    userId: number;
    personalDataId: number;
    shortName: string;
    longName: string;
  };
};

export type IAttachmentCorrespondenceLink = {
  id: number;
  typeOfAttachment: LinkAttachedToCorrespondenceTypeOfAttachmentEnum;
  shortNameOfTheDocument: string;
  employee: {
    id: number;
    personalDataId: number;
    shortName: string;
    longName: string;
  };
  linkedEntities: ILinkedEntities;
  createdAt: Date | string;
  descriptionForAttachment: string;
};

export type ICorrespondenceAttachedLinkColumnInTable = {
  id: number;
  sequenceNumber: number;
  link: string;
  linkedEntities: ILinkedEntities;
  createdAt: Date | string;
  linkAddedBy: string;
  note: string;
  assignment: string;
};

export enum LegalEntityEnum {
  legalEntity = "legalEntity",
  individual = "individual",
}

export enum ResolutionRecipientEmployeeEnums {
  CURRENT = "current",
  OTHER = "other",
}

export type ISendResolutionForm = {
  recipientEmployeeType: ResolutionRecipientEmployeeEnums;
  resCheckbox?: boolean;
  recipient?: {
    id?: number;
    type?: "employee" | "externalSubstitution";
  };
};

export type TDocumentExecutionTrackerNode = {
  id: number;
  taskId: number | null;
  typeEventId: TypeEventEnum;
  statusForAuthor: StatusForAuthorEnum;
  showTableResolution: boolean;
  scheduledDateOfExecution: null;
  createdAt: Date;
  typeEvent: {
    id: number;
    nameOfTypeEvent: string;
    createdAt: Date;
    updatedAt: Date;
  };
  resolutionTo: string | null;
  resolutionFrom: string | null;
  generatedText: string;
};

export enum CorrespondenceActionsTypeEnum {
  edit = "edit",
  generate = "generate",
}

export interface ICorrespondenceFilters {
  correspondentNames: string[];
  correspondentPositions?: string[];
  correspondentStructuralUnitNames?: string[];
  documentTopics: string[];
  notes: string[];
  organizationNames: string[];
  senderNames: string[];
  senders: string[];
  senderPositions?: string[];
  senderStructuralUnitNames?: string[];
}

export interface IAvailableTopic {
  id: number;
  text: string;
  organizationId: number;
  typeResourceId: number;
}

export enum TypeResourceEnum {
  Contract = 1,
  PersonnelOrder = 2,
  Order = 3,
  Directive = 4,
  IncomingMessage = 5,
  OutgoingMessage = 6,
  ServiceNote = 7,
  InternalProtocol = 8,
  CustomerProtocol = 9,
  WorkingPlan = 10,
  PowerOfAttorney = 11,
  JobDescription = 12,
  ProductionInstruction = 13,
  Organization = 14,
  TypicalApprovalRouteForContract = 15,
  Task = 16,
  Message = 17,
  User = 18,
}

export interface IDocumentApprovalRoute {
  personalData: IPersonalData;
  positionName: string;
  approverMission: string;
  structuralUnitType: StructuralUnitTypesForCorrespondenceEnum;
  amountOfDays: number;
  queueNumber: number;
  employeeId?: number;
  externalSubstitutionId: number;
  staffUnitId: number;
  structuralUnitId: number;
  status?: {
    singleApprovalId: number;
    statusSingleApproval: string;
  };
}

export interface IViewDocument {
  id: number;
  view: boolean;
}

export interface IGenerateDocumentForm {
  fileName?: string;
  templateId?: number;
  textOfTheDocument?: string;
}

export enum MethodOfSendingEnum {
  viaOrganizationEmail = "via_organization_email",
  viaApplication = "via_application",
  byEmail = "by_email",
  byRussianPost = "by_russian_post",
  viaCourierDeliveryService = "via_courier_delivery_service",
  byARepresentativeOfTheSendingOrganisation = "by_a_representative_of_the_sending_organisation",
}

export interface ILetterMessages {
  sendDate?: Date;
  fileId?: number;
  note?: string;
  methodOfSending?: MethodOfSendingEnum;
  senderEmailId?: number;
  recipientEmailId?: number;
  courierServiceName?: string;
  personalDataLongName?: string;
  briefTextOrganizationName?: string;
  sendFromSystem?: boolean;
  sendFromService?: boolean;
  organizationId?: number;
  personalDataId?: number;
  canSendViaOrganizationEmail?: boolean;
  externalSubstitutionId?: number;
  employeeId?: number;
  methodOfSystem?: MethodOfSendingEnum;
  file?: File | null;
  counterpartyId?: number;
}

export interface IMailData {
  messages: ILetterMessages[];
}
