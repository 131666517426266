import React, { useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import {
  useDeleteFeedbackRequestMutation,
  useEditFeedbackRequestTask,
} from "api";
import {
  closeDrawer,
  Paths,
  setModal,
  TransitionPrompt,
  useAppDispatch,
  useAppSelector,
} from "app";
import { Drawer, Icon } from "ui-kit";

import {
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  Typography,
  useTheme,
} from "@mui/material";
import { Button, Input } from "@sbm/ui-components";
import { IEditTask, ITaskById, ModalVariants } from "@types";

import { orderTextMaxLength } from "./constants";
import { CardWrapper, CardTitleWrapper } from "./styles";

interface Props {
  taskData: ITaskById;
}

export const EditFeedbackRequestTaskForm = ({ taskData }: Props) => {
  const { t } = useTranslation("tasks");
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { id: parentTaskId } = useParams();
  const navigate = useNavigate();

  const { drawer } = useAppSelector((state) => state.global);

  const [showPrompt, setShowPrompt] = useState(false);
  const form = useForm<IEditTask>({
    defaultValues: {
      highPriority: taskData.registration.priority,
      textOfTheTask: taskData.executionRequirements.textOfTheTask,
      personalControl: taskData.executionRequirements.personalControl,
    },
  });

  const onDeleteSuccess = () => {
    navigate(Paths.TECH_SUPPORT);
    handleCloseDrawer();
  };

  const { mutate: deleteFeedbackRequestTask, isLoading: isRemoving } =
    useDeleteFeedbackRequestMutation(onDeleteSuccess);

  const { reset, handleSubmit, control, register, formState } = form;

  const handleCloseDrawer = () => {
    reset();
    dispatch(closeDrawer());
  };

  const handleClose = () => {
    handleCloseDrawer();
  };

  const handleClosePrompt = () => {
    setShowPrompt(false);
  };
  const fields = useWatch({ control });

  const { highPriority, textOfTheTask, personalControl } = fields;

  const handleCancel = () => {
    handleClose();
  };

  const isDisableSaveButton = useMemo(() => {
    return (
      textOfTheTask === taskData.executionRequirements.textOfTheTask &&
      personalControl === taskData.executionRequirements.personalControl &&
      highPriority === taskData.registration.priority
    );
  }, [highPriority, textOfTheTask, personalControl, taskData]);

  const handleDeleteFeedbackRequest = () => {
    dispatch(
      setModal({
        open: true,
        variant: ModalVariants.removeTask,
        content: {
          data: {
            onRemove: () => {
              deleteFeedbackRequestTask(Number(parentTaskId));
            },
            state: {
              hasOneSiblingTask: taskData?.hasOneSiblingTask,
              areAllSiblingTasksDone: taskData?.areAllSiblingTasksDone,
              hasValidChildTasks: taskData?.hasValidChildTasks,
              parentTaskId: taskData?.parentTaskId,
            },
          },
        },
      })
    );
  };

  const onEditSuccess = () => {
    handleCloseDrawer();
  };
  const { mutate: editFeedbackRequestTask, isLoading: isLoadingEditTask } =
    useEditFeedbackRequestTask(onEditSuccess);

  const onSave = handleSubmit(async (data: IEditTask) => {
    const requestBody = {
      textOfTheTask: data.textOfTheTask,
      highPriority: data.highPriority,
      personalControl: data.personalControl,
    };
    editFeedbackRequestTask({
      data: requestBody,
      taskId: taskData.registration.taskNumber,
    });
  });

  return (
    <>
      {showPrompt && (
        <TransitionPrompt
          open={showPrompt}
          onClose={handleClosePrompt}
          onConfirm={handleCloseDrawer}
        />
      )}

      <Drawer
        title={t("editTask")}
        open={Boolean(drawer)}
        onClose={handleCancel}
        actions={
          <Stack
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Stack>
              <Button
                variant="text"
                size="medium"
                color="error"
                sx={{ fontSize: "15px" }}
                onClick={handleDeleteFeedbackRequest}
                loading={isRemoving}
                startIcon={
                  <Icon name="Trash2" color={theme.palette.secondary.main} />
                }
              >
                {t("delete")}
              </Button>
            </Stack>
            <Stack direction="row" spacing={3}>
              <Button
                variant="outlined"
                onClick={handleCancel}
                color="secondary"
                size="large"
              >
                {t("cancel")}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                loading={isLoadingEditTask}
                onClick={onSave}
                disabled={isDisableSaveButton}
              >
                {t("save.task")}
              </Button>
            </Stack>
          </Stack>
        }
      >
        <Stack position="relative">
          <form noValidate>
            <CardWrapper>
              <CardTitleWrapper>
                <Typography variant="h9_semiBold" color="text.disabled">
                  {t("execution_requirements")}
                </Typography>
              </CardTitleWrapper>
              <Input
                label={t("order_text")}
                variant="outlined"
                required
                maxLength={orderTextMaxLength}
                size="medium"
                multiline
                maxRows={10}
                linesCount={5}
                {...register("textOfTheTask")}
              />

              <Stack ml={3} mt={1}>
                <Typography variant="body3" color="text.disabled">
                  {t("feedback.request_text_helper")}
                </Typography>
              </Stack>

              <Grid container gap={2} mt={6}>
                <Grid item xs={12}>
                  <FormControlLabel
                    label={t("feedback.personal_control")}
                    control={
                      <Switch
                        color="secondary"
                        {...register("personalControl")}
                        defaultChecked={personalControl}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    label={t("higher_priority")}
                    control={
                      <Switch
                        color="secondary"
                        {...register("highPriority")}
                        defaultChecked={highPriority}
                      />
                    }
                  />
                </Grid>
              </Grid>
            </CardWrapper>
          </form>
        </Stack>
      </Drawer>
    </>
  );
};
