import toast from "react-hot-toast";

import { axiosService } from "app";
import { AxiosError } from "axios";

import { getAPIErrorMessage } from "@sbm/fe-utils";

export const fetchEmployees = async (
  search = "",
  organizationId?: number,
  subordinate?: boolean,
  limit?: number,
  headOfStructuralUnit?: boolean
) => {
  const params: {
    limit: number;
    page: number;
    search: string;
    "filter.organizationId"?: number;
    "filter.subordinate"?: boolean;
    "filter.headOfStructuralUnit"?: boolean;
  } = {
    limit: limit || 200,
    page: 1,
    search,
  };

  if (organizationId) {
    params["filter.organizationId"] = organizationId;
  }

  if (subordinate) {
    params["filter.subordinate"] = subordinate;
  }

  if (headOfStructuralUnit) {
    params["filter.headOfStructuralUnit"] = headOfStructuralUnit;
  }

  const { data } = await axiosService({
    endpoint: `employees`,
    body: params,
  });

  return data;
};

export const fetchEmployeesInHolding = async (
  search = "",
  limit?: number,
  organizationId?: number,
  headOfStructuralUnit?: boolean
) => {
  const params: {
    limit: number;
    page: number;
    search: string;
    "filter.organizationId"?: number;
    "filter.headOfStructuralUnit"?: boolean;
  } = {
    limit: limit || 200,
    page: 1,
    search,
  };

  if (organizationId) {
    params["filter.organizationId"] = organizationId;
  }

  if (headOfStructuralUnit) {
    params["filter.headOfStructuralUnit"] = headOfStructuralUnit;
  }

  try {
    const { data } = await axiosService({
      endpoint: `employees/in-holding`,
      body: params,
    });

    return data;
  } catch (e) {
    const errorMessage = getAPIErrorMessage(e as AxiosError);
    toast.error(errorMessage);
  }
};

export const fetchEmployeeById = async (id?: number) => {
  const { data } = await axiosService({
    endpoint: `employees/${id}`,
  });

  return data;
};
