import { Palette } from "@mui/material";
import { TIconNames, TypeEventEnum } from "@types";

// ToDo: Add hardcoded numbers to TypeEventEnum after BE will do it
export const getIconName = (typeEventId: number): TIconNames | null => {
  switch (typeEventId) {
    case TypeEventEnum.CreateTask:
    case TypeEventEnum.CreateIncomingDocument:
    case TypeEventEnum.CreateOutgoingDocument:
    case TypeEventEnum.CreateServiceNote:
    case TypeEventEnum.CreateContract:
      return "FilePlus2";
    case TypeEventEnum.DelegateTask:
      return "CornerDownRight";
    case TypeEventEnum.EditTaskExecutorField:
    case TypeEventEnum.EditTaskTextField:
    case TypeEventEnum.EditTaskDueDate:
    case TypeEventEnum.EditTaskAssignee:
    case TypeEventEnum.NotifyAssigneeOfDueDateChange:
    case TypeEventEnum.NotifyAssigneeOfAssigneeChange:
    case TypeEventEnum.EditContractCard:
    case TypeEventEnum.EditAttachmentToTheTask:
    case TypeEventEnum.ChangeContractResponsible:
    case TypeEventEnum.EditAttachedFileInIncomingDocument:
    case TypeEventEnum.EditAttachedFileInOutgoingDocument:
    case TypeEventEnum.EditAttachedFileInServiceNote:
    case 91:
    case 98:
      return "Pencil";
    case TypeEventEnum.EditTaskScheduledDateOfExecutionField:
      return "Calendar";
    case TypeEventEnum.DeleteTask:
    case TypeEventEnum.NotifyExecutorOfTaskDeletion:
    case TypeEventEnum.DeleteAttachmentToTheTask:
    case TypeEventEnum.ContractDeletion:
    case 74:
    case TypeEventEnum.DeleteIncomingDocument:
    case TypeEventEnum.DeleteOutgoingDocument:
    case TypeEventEnum.DeleteServiceNote:
    case TypeEventEnum.DeleteAttachedFileFromIncomingDocument:
    case TypeEventEnum.DeleteAttachedFileFromOutgoingDocument:
    case TypeEventEnum.DeleteAttachedFileFromServiceNote:
    case 92:
      return "Trash2";
    case TypeEventEnum.SendTaskToAssignee:
    case TypeEventEnum.SendContractToContractDepartment:
    case TypeEventEnum.SendTaskToArchiveByUser:
    case 65:
    case 99:
      return "Send";
    case TypeEventEnum.TaskAccepted:
    case 71:
      return "Check";
    case TypeEventEnum.MarkTaskAsPersonallyCompleted:
    case TypeEventEnum.AutoMarkTaskAsCompleted:
    case TypeEventEnum.ApproveTaskCompletion:
    case TypeEventEnum.CompleteDocumentExecution:
    case TypeEventEnum.IncomingDocumentExecutionOfAllResolutions:
    case 72:
      return "CheckCheck";
    case TypeEventEnum.AutoMarkTaskAsRemovedFromControl:
    case TypeEventEnum.UpdateParentAssignmentStatus:
    case TypeEventEnum.TaskOverdue:
    case TypeEventEnum.ContractStatusChange:
    case TypeEventEnum.SendTaskToArchiveBySystem:
    case TypeEventEnum.CancelTaskDoneStatus:
    case TypeEventEnum.CorrespondenceApprovalRouteConfiguration:
    case TypeEventEnum.ContractApprovalRouteConfiguration:
      return "Settings";
    case TypeEventEnum.ReturnTaskForRework:
      return "Reply";
    case TypeEventEnum.NotifyAssigneeOfReturnForRework:
    case TypeEventEnum.NotifyAssigneeOfOverdueTask:
    case TypeEventEnum.NotifyAuthorOfOverdueTask:
    case TypeEventEnum.NotifyAssigneeOfDueDateChangeRepeat:
    case TypeEventEnum.NotifyAuthorOfTaskRejection:
    case TypeEventEnum.NotifyAuthorOfTaskCompletion:
    case TypeEventEnum.NotifyAuthorOfExecutionInterruption:
    case TypeEventEnum.NotifyAssigneeOfNewTask:
    case TypeEventEnum.NotifyAssigneeOfTaskChange:
    case TypeEventEnum.NotifyAssigneeOfApproachingDueDate:
    case TypeEventEnum.NotifyAuthorAndExecutorOfTaskCancellation:
    case TypeEventEnum.NotifyRecipientOfResolutionTransfer:
    case TypeEventEnum.NotifyAuthorOfRequestRegistration:
    case TypeEventEnum.NotifyExecutorOfHighPriorityRequest:
    case 66:
    case 68:
    case 70:
    case 73:
    case 78:
    case 107:
      return "Info";
    case TypeEventEnum.RejectTask:
    case TypeEventEnum.CancellationOfAPreviouslyIssuedResolution:
    case 67:
      return "XCircle";
    case TypeEventEnum.IssueResolutionOnDocument:
    case TypeEventEnum.SendIncomingDocumentForResolution:
      return "FileSignature";
    case TypeEventEnum.AttachFile:
      return "Paperclip";
    case TypeEventEnum.ViewTaskByAssignee:
    case TypeEventEnum.ViewTaskByAuthor:
      return "Eye";
    case TypeEventEnum.AttachLinkToDocument:
      return "Link";
    case TypeEventEnum.TaskExecutionInterrupted:
    case 106:
      return "Unplug";
    case TypeEventEnum.CreateRequestToSBM:
      return "MessageSquarePlus";
    case TypeEventEnum.ContractResponsibleAssignment:
      return "User2Icon";
    case TypeEventEnum.ContractAssigningNumber:
      return "FileDigit";
    case TypeEventEnum.RegisterRequest:
      return "PenSquare";
    case 69:
      return "MessageSquarePlus";
    case TypeEventEnum.CreatePresignedContract:
      return "Plus";
    case 101:
    case 102:
    case 103:
    case 104:
    case 105:
      return "Mail";
    default:
      return null;
  }
};

export const getIconBGColor = (typeEventId: number, palette: Palette) => {
  switch (typeEventId) {
    case TypeEventEnum.EditTaskDueDate:
    case TypeEventEnum.AttachLinkToDocument:
    case TypeEventEnum.AttachFile:
    case TypeEventEnum.EditTaskAssignee:
    case TypeEventEnum.NotifyAssigneeOfDueDateChange:
    case TypeEventEnum.NotifyAssigneeOfAssigneeChange:
    case TypeEventEnum.EditTaskExecutorField:
    case TypeEventEnum.EditTaskTextField:
    case TypeEventEnum.EditTaskScheduledDateOfExecutionField:
    case TypeEventEnum.EditContractCard:
    case TypeEventEnum.EditAttachmentToTheTask:
    case TypeEventEnum.ChangeContractResponsible:
    case TypeEventEnum.EditAttachedFileInIncomingDocument:
    case TypeEventEnum.EditAttachedFileInOutgoingDocument:
    case TypeEventEnum.EditAttachedFileInServiceNote:
    case TypeEventEnum.ContractAssigningNumber:
    case TypeEventEnum.CorrespondenceApprovalRouteConfiguration:
    case 69:
    case 91:
    case 98:
    case TypeEventEnum.ContractApprovalRouteConfiguration:
      return palette.secondary.main;
    case TypeEventEnum.MarkTaskAsPersonallyCompleted:
    case TypeEventEnum.AutoMarkTaskAsCompleted:
    case TypeEventEnum.ApproveTaskCompletion:
    case TypeEventEnum.CompleteDocumentExecution:
    case TypeEventEnum.IncomingDocumentExecutionOfAllResolutions:
      return palette.success.main;
    case TypeEventEnum.AutoMarkTaskAsRemovedFromControl:
    case TypeEventEnum.TaskAccepted:
    case TypeEventEnum.UpdateParentAssignmentStatus:
    case TypeEventEnum.TaskOverdue:
    case TypeEventEnum.ContractStatusChange:
    case TypeEventEnum.SendTaskToArchiveBySystem:
    case TypeEventEnum.CancelTaskDoneStatus:
      return palette.primary.contrastText;

    case TypeEventEnum.ReturnTaskForRework:
    case TypeEventEnum.RejectTask:
    case TypeEventEnum.TaskExecutionInterrupted:
    case TypeEventEnum.CancellationOfAPreviouslyIssuedResolution:
    case TypeEventEnum.DeleteTask:
    case TypeEventEnum.NotifyExecutorOfTaskDeletion:
    case TypeEventEnum.DeleteAttachmentToTheTask:
    case TypeEventEnum.ContractDeletion:
    case 67:
    case 74:
    case TypeEventEnum.DeleteIncomingDocument:
    case TypeEventEnum.DeleteOutgoingDocument:
    case TypeEventEnum.DeleteServiceNote:
    case TypeEventEnum.DeleteAttachedFileFromIncomingDocument:
    case TypeEventEnum.DeleteAttachedFileFromOutgoingDocument:
    case TypeEventEnum.DeleteAttachedFileFromServiceNote:
    case 106:
    case 92:
      return palette.error.main;
    default:
      return palette.primary.main;
  }
};

export const getIconBorderColor = (typeEventId: number, palette: Palette) => {
  switch (typeEventId) {
    case TypeEventEnum.AutoMarkTaskAsRemovedFromControl:
    case TypeEventEnum.UpdateParentAssignmentStatus:
    case TypeEventEnum.TaskOverdue:
    case TypeEventEnum.ContractStatusChange:
    case TypeEventEnum.SendTaskToArchiveBySystem:
    case TypeEventEnum.CancelTaskDoneStatus:
      return palette.primary.main;
    case TypeEventEnum.TaskAccepted:
      return palette.success.main;
    default:
      return "";
  }
};

export const getIconColor = (typeEventId: number, palette: Palette) => {
  switch (typeEventId) {
    case TypeEventEnum.AutoMarkTaskAsRemovedFromControl:
    case TypeEventEnum.UpdateParentAssignmentStatus:
    case TypeEventEnum.TaskOverdue:
    case TypeEventEnum.ContractStatusChange:
    case TypeEventEnum.SendTaskToArchiveBySystem:
    case TypeEventEnum.CancelTaskDoneStatus:
      return palette.primary.main;
    case TypeEventEnum.TaskAccepted:
      return palette.success.main;
    default:
      return palette.primary.contrastText;
  }
};
