import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  useGetCorrespondenceFilesById,
  useSendToResolutionMutationServiceNote,
} from "api";
import {
  openDrawer,
  SendMailContainer,
  setModal,
  useAppDispatch,
  useAppSelector,
  useQueryParams,
} from "app";
import { Icon } from "ui-kit";

import {
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import {
  getLocaleDateFormat,
  getStorageItem,
  hexToRgba,
  STORAGE_KEYS,
} from "@sbm/fe-utils";
import { DelayedButton, Button } from "@sbm/ui-components";
import {
  CorrespondenceTypeEnum,
  IDocumentById,
  ModalVariants,
  CorrespondenceActionsTypeEnum,
  StatusForCorrespondenceEnum,
  StructuralUnitTypesForCorrespondenceEnum,
  DrawerVariantsEnum,
  IStructuralUnit,
} from "@types";

import Confidential_image_en from "../../../../../assets/backgrounds/confidential_img_en.png";
import Confidential_image_ru from "../../../../../assets/backgrounds/confidential_img_ru.png";
import { AdjustAgreementRouteContainer } from "../../AdjustAgreementRouteContainer";
import { EllipsisTextWrapper } from "../DocumentCard/styles";
import { FilePreviewDrawer } from "../FilePreviewDrawer";
import { GenerateFileDrawer } from "../GenerateFileDrawer";
import { IncomingDocumentActionsMenu } from "./IncomingDocumentActionsMenu";
import { OutgoingDocumentActionsMenu } from "./OutgoingDocumentActionsMenu";
import { ServiceNotesActionsMenu } from "./ServiceNotesActions";
import { CardWrapper, LegalEntityImg } from "./styles";

interface DocumentDetailsProps {
  data: IDocumentById;
  showAgreementTab: boolean;
}

export const DocumentDetails: React.FC<DocumentDetailsProps> = ({
  data,
  showAgreementTab,
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { get } = useQueryParams();
  const navigate = useNavigate();
  const { t } = useTranslation("correspondence");

  const locale = getStorageItem(STORAGE_KEYS.lang);
  const isEnglish = locale === "en";

  const queryToNavigateBack = get("navigateBackTo", false) as string;

  const { userProfile } = useAppSelector((state) => state.auth);

  const {
    registeredByPersonalDataId,
    statusForCorrespondence,
    constructionComplexes,
    sender,
    recipients,
    registeredByLongName,
    internalRegistrationNumber,
    registrationDate,
    numberAtCounterparty,
    dateOfRegistration,
    createdAt,
    correspondenceType,
    initiatingCorrespondenceRegNumber,
    initiatingCorrespondenceDate,
    topicForTheCorrespondence,
    textForTopic,
    isResponsibleForSending,
    attachmentsCount,
    hasSignedMainDocument,
    id,
  } = data;

  const { data: attachedFiles } = useGetCorrespondenceFilesById(id);

  const isOutGoingDocument =
    correspondenceType === CorrespondenceTypeEnum.outgoingDocument;
  const isIncomingDocument =
    correspondenceType === CorrespondenceTypeEnum.incomingDocument;
  const isServiceNote =
    correspondenceType === CorrespondenceTypeEnum.serviceNote;

  const isSetupApprovalDisabled = showAgreementTab; // When agreement tab is shown disable approval setup

  const isUserDocumentCreator =
    userProfile?.personalDataId === registeredByPersonalDataId;
  const isUserSender = userProfile?.personalDataId === sender.personalDataId;

  const handleEdit = useCallback(() => {
    dispatch(openDrawer(DrawerVariantsEnum.editCorrespondence));
  }, [dispatch]);

  const handleSendToResolution = useCallback(() => {
    dispatch(
      setModal({
        open: true,
        variant: ModalVariants.sendToResolution,
        content: {
          data,
        },
      })
    );
  }, [dispatch, data]);

  const hadValidFiles = React.useMemo(() => {
    const hasValidFile = attachedFiles?.files?.some(
      (file) =>
        file.typeOfAttachment === "main_document" &&
        (file.fileStatus as string) === "signed_document"
    );

    return !attachedFiles?.files?.length || !hasValidFile;
  }, [attachedFiles]);

  const handleSendToResolutionServiceNotesPopup = useCallback(() => {
    dispatch(
      setModal({
        open: true,
        variant: ModalVariants.sendToResolutionServiceNotes,
      })
    );
  }, [dispatch]);

  const { mutate: sendToResolution, isLoading: isSendToResolution } =
    useSendToResolutionMutationServiceNote();

  const handleSendToResolutionServiceNotes = useCallback(() => {
    const requestData = {
      correspondenceId: id,
    };
    sendToResolution(requestData);
  }, [id, sendToResolution]);

  const handleAdjustAgreementRoute = useCallback(() => {
    dispatch(openDrawer(DrawerVariantsEnum.adjustAgreementRoute));
  }, [dispatch]);

  const navigateBack = () => {
    if (!queryToNavigateBack) return;

    navigate(queryToNavigateBack);
  };

  const handleSendMail = useCallback(() => {
    dispatch(openDrawer(DrawerVariantsEnum.sendMail));
  }, [dispatch]);

  const handleSendToDispatch = useCallback(() => {
    if (attachmentsCount && hasSignedMainDocument) {
      dispatch(
        setModal({
          open: true,
          variant: ModalVariants.transferForSendingComment,
          content: { data: { correspondenceId: id } },
        })
      );
    } else {
      dispatch(
        setModal({
          open: true,
          variant: ModalVariants.transferForSending,
        })
      );
    }
  }, [attachmentsCount, id, dispatch, hasSignedMainDocument]);

  const renderButton = useMemo(() => {
    if (isOutGoingDocument) {
      if (isUserDocumentCreator && isResponsibleForSending) {
        switch (statusForCorrespondence) {
          case StatusForCorrespondenceEnum.toBeAgreed:
            return (
              <Stack direction="row" gap={2} alignItems="center">
                <Button
                  size="medium"
                  variant="contained"
                  color="secondary"
                  onClick={handleEdit}
                >
                  {t("edit")}
                </Button>
                <OutgoingDocumentActionsMenu
                  actionTypes={[CorrespondenceActionsTypeEnum.generate]}
                />
              </Stack>
            );
          case StatusForCorrespondenceEnum.atSigning:
            return (
              <Button
                onClick={handleSendMail}
                size="medium"
                variant="contained"
                color="secondary"
              >
                {t("send")}
              </Button>
            );
          case StatusForCorrespondenceEnum.draft:
            return (
              <Stack direction="row" gap={2} alignItems="center">
                <Button
                  size="medium"
                  variant="contained"
                  color="secondary"
                  onClick={handleAdjustAgreementRoute}
                  disabled={isSetupApprovalDisabled}
                >
                  {t("set_up_approval")}
                </Button>
                <OutgoingDocumentActionsMenu
                  actionTypes={[
                    CorrespondenceActionsTypeEnum.edit,
                    CorrespondenceActionsTypeEnum.generate,
                  ]}
                />
              </Stack>
            );
          default:
            return null;
        }
      } else if (isUserDocumentCreator) {
        switch (statusForCorrespondence) {
          case StatusForCorrespondenceEnum.draft:
            return (
              <Stack direction="row" gap={2} alignItems="center">
                <Button
                  size="medium"
                  variant="contained"
                  color="secondary"
                  onClick={handleAdjustAgreementRoute}
                  disabled={isSetupApprovalDisabled}
                >
                  {t("set_up_approval")}
                </Button>
                <OutgoingDocumentActionsMenu
                  actionTypes={[
                    CorrespondenceActionsTypeEnum.edit,
                    CorrespondenceActionsTypeEnum.generate,
                  ]}
                />
              </Stack>
            );
          case StatusForCorrespondenceEnum.toBeAgreed:
            return (
              <Stack direction="row" gap={2} alignItems="center">
                <Button
                  size="medium"
                  variant="contained"
                  color="secondary"
                  onClick={handleEdit}
                >
                  {t("edit")}
                </Button>
                <OutgoingDocumentActionsMenu
                  actionTypes={[CorrespondenceActionsTypeEnum.generate]}
                />
              </Stack>
            );
          case StatusForCorrespondenceEnum.atSigning:
            return (
              <Button
                size="medium"
                variant="contained"
                color="secondary"
                onClick={handleSendToDispatch}
                sx={{ minWidth: "200px" }}
              >
                {t("send_to_dispatch")}
              </Button>
            );
          default:
            return null;
        }
      } else if (isResponsibleForSending) {
        switch (statusForCorrespondence) {
          case StatusForCorrespondenceEnum.dispatch:
            return (
              <Button
                onClick={handleSendMail}
                size="medium"
                variant="contained"
                color="secondary"
              >
                {t("send")}
              </Button>
            );
          default:
            return null;
        }
      }
    } else if (isServiceNote) {
      if (isUserDocumentCreator) {
        switch (statusForCorrespondence) {
          case StatusForCorrespondenceEnum.draft:
            return (
              <Stack direction="row" gap={2} alignItems="center">
                <Button
                  size="medium"
                  variant="contained"
                  color="secondary"
                  onClick={handleAdjustAgreementRoute}
                  disabled={isSetupApprovalDisabled}
                >
                  {t("set_up_approval")}
                </Button>
                <ServiceNotesActionsMenu
                  actionTypes={[
                    CorrespondenceActionsTypeEnum.edit,
                    CorrespondenceActionsTypeEnum.generate,
                  ]}
                />
              </Stack>
            );
          case StatusForCorrespondenceEnum.toBeAgreed:
            return (
              <Stack direction="row" gap={2} alignItems="center">
                <Button
                  size="medium"
                  variant="contained"
                  color="secondary"
                  onClick={handleEdit}
                >
                  {t("edit")}
                </Button>
                <ServiceNotesActionsMenu
                  actionTypes={[CorrespondenceActionsTypeEnum.generate]}
                />
              </Stack>
            );
          case StatusForCorrespondenceEnum.atSigning:
            return isServiceNote ? (
              hadValidFiles ? (
                <Button
                  size="medium"
                  variant="contained"
                  color="secondary"
                  onClick={handleSendToResolutionServiceNotesPopup}
                >
                  {t("send_to_resolution")}
                </Button>
              ) : (
                <DelayedButton
                  size="large"
                  color="secondary"
                  variant="contained"
                  onClick={handleSendToResolutionServiceNotes}
                  loading={isSendToResolution}
                >
                  {t("send_to_resolution")}
                </DelayedButton>
              )
            ) : (
              <Button
                size="medium"
                variant="contained"
                color="secondary"
                onClick={handleSendToResolution}
              >
                {t("send_to_resolution")}
              </Button>
            );
          default:
            return null;
        }
      } else if (isUserSender) {
        switch (statusForCorrespondence) {
          case StatusForCorrespondenceEnum.toBeAgreed:
            return (
              <Button
                size="medium"
                variant="contained"
                color="secondary"
                onClick={handleEdit}
              >
                {t("edit")}
              </Button>
            );
          case StatusForCorrespondenceEnum.atSigning:
            return (
              <Stack direction="row" gap={2} alignItems="center">
                <Button size="medium" variant="contained" color="secondary">
                  {t("send_to_resolution")}
                </Button>
                <ServiceNotesActionsMenu
                  actionTypes={[CorrespondenceActionsTypeEnum.edit]}
                />
              </Stack>
            );
          default:
            return null;
        }
      }
    } else {
      switch (statusForCorrespondence) {
        case StatusForCorrespondenceEnum.draft:
          return isUserDocumentCreator ? (
            <Stack direction="row" gap={2} alignItems="center">
              <Button
                size="medium"
                variant="contained"
                color="secondary"
                onClick={handleSendToResolution}
              >
                {t("send_to_resolution")}
              </Button>
              <IncomingDocumentActionsMenu />
            </Stack>
          ) : null;
        case StatusForCorrespondenceEnum.onResolution:
          return isUserSender ? (
            <Button
              size="medium"
              variant="contained"
              color="secondary"
              onClick={handleEdit}
            >
              {t("edit")}
            </Button>
          ) : null;
        default:
          return null;
      }
    }
  }, [
    isSetupApprovalDisabled,
    isOutGoingDocument,
    isServiceNote,
    statusForCorrespondence,
    handleAdjustAgreementRoute,
    t,
    handleEdit,
    handleSendToResolution,
    handleSendToResolutionServiceNotes,
    handleSendToResolutionServiceNotesPopup,
    isSendToResolution,
    hadValidFiles,
    handleSendMail,
    isUserDocumentCreator,
    isUserSender,
    isResponsibleForSending,
    handleSendToDispatch,
  ]);

  const complexes = constructionComplexes
    .map((complex) => {
      return complex?.briefTextConstructionComplexName;
    })
    .toString()
    .replaceAll(",", ", ");

  const recipientCount = recipients.length;

  const showStructuralUnit = (type?: Partial<IStructuralUnit> | undefined) => {
    if (!type) return false;

    return !(
      type.structuralUnitType ===
        StructuralUnitTypesForCorrespondenceEnum.goveringBody ||
      type.structuralUnitType ===
        StructuralUnitTypesForCorrespondenceEnum.mainGoverningBody
    );
  };

  const recipientGridWidth = useMemo(() => {
    switch (recipientCount) {
      case 1:
        return { xs: 12, md: 6, xl: 3 };
      case 2:
        return { xs: 12, md: 6, xl: 6 };
      case 3:
        return { xs: 12, md: 12, xl: 9 };
      case 4:
        return { xs: 12, md: 12, xl: 9 };
      default:
        return { xs: 12, md: 12, xl: 9 };
    }
  }, [recipientCount]);

  const senderGridWidth = useMemo(() => {
    switch (recipientCount) {
      case 1:
        return { xs: 12, md: 6, xl: 3 };
      case 2:
        return { xs: 12, md: 6, xl: 6 };
      case 3:
      case 4:
        return { xs: 12, md: 6, xl: 3 };
      default:
        return { xs: 12, md: 6, xl: 3 };
    }
  }, [recipientCount]);

  const registrationGridWidth = useMemo(() => {
    switch (recipientCount) {
      case 1:
        return { xs: 12, md: 12, xl: 6 };
      case 2:
        return { xs: 12, md: 12, xl: 12 };
      case 3:
        return { xs: 12, md: 12, xl: 12 };
      case 4:
        return { xs: 12, md: 12, xl: 12 };
      default:
        return { xs: 12, md: 12, xl: 12 };
    }
  }, [recipientCount]);

  const otherRecipients =
    recipients.length > 3 ? recipients.slice(3) : recipients;
  const recipientsToShow =
    recipients.length > 3 ? recipients.slice(0, 3) : recipients;

  const senderShow = useMemo(() => {
    if (isOutGoingDocument || isServiceNote) {
      return true;
    }
    if (isIncomingDocument) {
      return data.sender.legalEntity;
    }
  }, [
    data.sender.legalEntity,
    isOutGoingDocument,
    isServiceNote,
    isIncomingDocument,
  ]);

  const recipientShow = (legalEntity?: boolean) => {
    if (isIncomingDocument || isServiceNote) {
      return true;
    }
    return legalEntity;
  };

  const handleOpenFilePreviewDrawer = () => {
    dispatch(openDrawer(DrawerVariantsEnum.filePreview));
  };

  const showNumberOtCorrespondence = useMemo(() => {
    if (isIncomingDocument) {
      return numberAtCounterparty;
    }
    if (isOutGoingDocument || isServiceNote) {
      return initiatingCorrespondenceRegNumber;
    }
  }, [
    numberAtCounterparty,
    isIncomingDocument,
    isOutGoingDocument,
    initiatingCorrespondenceRegNumber,
    isServiceNote,
  ]);

  const showDate = useMemo(() => {
    if (isIncomingDocument) {
      return dateOfRegistration;
    }
    if (isServiceNote || isOutGoingDocument) {
      return initiatingCorrespondenceDate;
    }
  }, [
    isIncomingDocument,
    isOutGoingDocument,
    initiatingCorrespondenceDate,
    isServiceNote,
    dateOfRegistration,
  ]);

  const tooltipContent = otherRecipients?.map((r, index) => {
    return (
      <Stack
        flexDirection="column"
        key={`${r}-${index}`}
        borderTop={!index ? 0 : 1}
        borderColor={hexToRgba(theme.palette.secondary.contrastText, 0.7)}
        my={2}
        pt={!index ? 0 : 2}
      >
        <EllipsisTextWrapper $height={17}>
          <Typography
            variant="body3"
            component="div"
            color={hexToRgba(theme.palette.primary.contrastText, 0.7)}
          >
            {`${
              r.personalDataShortName
                ? r.personalDataShortName
                : r.personalDataLongName
            }${r.position ? " / " : " "}
                `}
            {r.position || `(${t("viewDocument.individual")})`}
          </Typography>
        </EllipsisTextWrapper>

        {isServiceNote && showStructuralUnit(r?.structuralUnit) && (
          <EllipsisTextWrapper $height={17}>
            <Typography
              variant="body3"
              fontWeight="bold"
              color={hexToRgba(theme.palette.primary.contrastText, 0.7)}
            >
              {r?.structuralUnit && r.structuralUnit.nameOfStructuralUnit}
            </Typography>
          </EllipsisTextWrapper>
        )}

        <EllipsisTextWrapper $height={17}>
          <Typography
            variant="body3"
            fontWeight="bold"
            color={hexToRgba(theme.palette.primary.contrastText, 0.7)}
          >
            {r.briefTextOrganizationName}
          </Typography>
        </EllipsisTextWrapper>
      </Stack>
    );
  });

  const recipient_data = (
    <Grid
      item
      xs={recipientGridWidth.xs}
      md={recipientGridWidth.md}
      xl={
        isServiceNote
          ? recipientGridWidth.xl
          : isOutGoingDocument
          ? recipientGridWidth.xl
          : 3
      }
    >
      <CardWrapper>
        <Typography variant="h9_semiBold" color="text.disabled">
          {t(
            recipients.length > 1
              ? "viewDocument.recipient_data_to"
              : "viewDocument.recipient_data_to"
          ).toUpperCase()}
        </Typography>

        <Stack
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          gap={4}
        >
          {recipientsToShow?.map((r, index) => {
            return recipientShow(r.legalEntity) ? (
              <Grid
                key={index}
                display="flex"
                flexDirection="column"
                xs={recipientsToShow.length < 3 ? 12 : 6}
                gap={4}
                pl={!index ? 0 : 4}
                borderLeft={!index ? 0 : 1}
                borderColor="other.divider"
              >
                <Stack>
                  <Typography variant="body4" color="text.disabled">
                    {t("viewDocument.organization")}
                  </Typography>
                  <Typography variant="h9_semiBold">
                    {r.briefTextOrganizationName}
                  </Typography>
                </Stack>
                {isServiceNote && showStructuralUnit(r?.structuralUnit) && (
                  <Stack>
                    <Typography variant="body4" color="text.disabled">
                      {t("document.structural_unit")}
                    </Typography>
                    <Typography variant="h9_semiBold">
                      {r?.structuralUnit &&
                        r.structuralUnit.nameOfStructuralUnit}
                    </Typography>
                  </Stack>
                )}
                <Stack>
                  <Typography variant="body4" color="text.disabled">
                    {t("viewDocument.position")}
                  </Typography>
                  <Typography variant="h9_semiBold">{r.position}</Typography>
                </Stack>
                <Stack>
                  <Typography variant="body4" color="text.disabled">
                    {t("viewDocument.full_name")}
                  </Typography>
                  <Typography variant="h9_semiBold">
                    {r.personalDataLongName}
                  </Typography>
                </Stack>
              </Grid>
            ) : (
              <Stack
                display="flex"
                flexDirection="row"
                width="100%"
                justifyContent="space-between"
                gap={4}
                position="relative"
                borderLeft={!index ? 0 : 1}
                borderColor="other.divider"
                pl={!index ? 0 : 4}
              >
                <Stack>
                  <Typography
                    variant="body4"
                    color="text.disabled"
                    sx={{ whiteSpace: "nowrap" }}
                  >
                    {t("viewDocument.full_name")}
                  </Typography>
                  <Typography variant="h9_semiBold">
                    {r.personalDataLongName}
                  </Typography>
                  {/*96 === 4*18(empty text) + 2*12(2sections gap)*/}
                  <Stack height={96} />
                </Stack>
                <LegalEntityImg $isEnglish={isEnglish} />
              </Stack>
            );
          })}
          {recipients.length - recipientsToShow.length > 0 ? (
            <Stack
              display="flex"
              flexDirection="row"
              gap={4}
              pl={4}
              borderLeft={1}
              borderColor="other.divider"
              alignItems="center"
            >
              <Tooltip
                arrow
                placement="top"
                title={tooltipContent}
                disableInteractive
              >
                <div className="recipientCount">
                  <Typography
                    variant="h7"
                    fontWeight={600}
                    sx={{ color: theme.palette.secondary.main }}
                  >
                    {`+${otherRecipients.length}`}
                  </Typography>
                </div>
              </Tooltip>
            </Stack>
          ) : null}
        </Stack>
      </CardWrapper>
    </Grid>
  );

  const sender_data = (
    <Grid
      item
      xs={senderGridWidth.xs}
      md={senderGridWidth.md}
      xl={isOutGoingDocument ? senderGridWidth.xl : 3}
    >
      <CardWrapper>
        <Typography variant="h9_semiBold" color="text.disabled">
          {t("viewDocument.sender_data_from").toUpperCase()}
        </Typography>
        {senderShow ? (
          <>
            <Stack>
              <Typography variant="body4" color="text.disabled">
                {t("viewDocument.organization")}
              </Typography>
              <Typography variant="h9_semiBold">
                {data.sender.briefTextOrganizationName}
              </Typography>
            </Stack>
            {isServiceNote &&
              showStructuralUnit(data.sender.structuralUnit) && (
                <Stack>
                  <Typography variant="body4" color="text.disabled">
                    {t("document.structural_unit")}
                  </Typography>
                  <Typography variant="h9_semiBold">
                    {data.sender.position}
                  </Typography>
                </Stack>
              )}
            <Stack>
              <Typography variant="body4" color="text.disabled">
                {t("viewDocument.position")}
              </Typography>
              {sender.position ? (
                <Typography variant="h9_semiBold">{sender.position}</Typography>
              ) : (
                <Stack height={18} />
              )}
            </Stack>
            <Stack>
              <Typography variant="body4" color="text.disabled">
                {t("viewDocument.full_name")}
              </Typography>
              <Typography variant="h9_semiBold">
                {sender.personalDataLongName}
              </Typography>
            </Stack>
          </>
        ) : (
          <Stack
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            width="100%"
            gap={4}
          >
            <Stack>
              <Typography
                variant="body4"
                color="text.disabled"
                sx={{ whiteSpace: "nowrap" }}
              >
                {t("viewDocument.full_name")}
              </Typography>
              <Typography variant="h9_semiBold">
                {sender.personalDataLongName}
              </Typography>
              {/*96 === 4*18(empty text) + 2*12(2sections gap)*/}
              <Stack height={96} />
            </Stack>

            <LegalEntityImg $isSender $isEnglish={isEnglish} />
          </Stack>
        )}
      </CardWrapper>
    </Grid>
  );

  const registration = (
    <Grid
      item
      xs={registrationGridWidth.xs}
      md={registrationGridWidth.md}
      xl={registrationGridWidth.xl}
    >
      <CardWrapper>
        <Typography variant="h9_semiBold" color="text.disabled">
          {t("viewDocument.registration").toUpperCase()}
        </Typography>
        <Grid container spacing={3}>
          <Grid xs={4} item>
            <Grid container spacing={3}>
              <Grid xs={6} item>
                <Stack>
                  <Typography variant="body4" color="text.disabled">
                    {isOutGoingDocument
                      ? t("viewDocument.outgoing_number")
                      : isServiceNote
                      ? t("viewDocument.service_note")
                      : t("viewDocument.incoming_number")}
                  </Typography>
                  <Typography variant="h9_semiBold">
                    {internalRegistrationNumber}
                  </Typography>
                </Stack>
              </Grid>
              <Grid xs={6} item>
                <Stack borderRight={1} borderColor="text.disabled">
                  <Typography variant="body4" color="text.disabled">
                    {t("viewDocument.date")}
                  </Typography>
                  <Typography variant="h9_semiBold">
                    {getLocaleDateFormat(registrationDate)}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={4} item>
            <Grid container spacing={3}>
              <Grid xs={6} item>
                <Stack>
                  <Typography variant="body4" color="text.disabled">
                    {isServiceNote
                      ? t("document.in_response_to")
                      : t("viewDocument.number_at_Correspondence")}
                  </Typography>
                  <Typography variant="h9_semiBold">
                    {showNumberOtCorrespondence}
                  </Typography>
                </Stack>
              </Grid>
              <Grid xs={6} item>
                <Stack borderRight={1} borderColor="text.disabled">
                  <Typography variant="body4" color="text.disabled">
                    {t("viewDocument.date")}
                  </Typography>
                  <Typography variant="h9_semiBold">
                    {getLocaleDateFormat(showDate)}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={4} item>
            <Grid container spacing={3}>
              <Grid xs={6} item>
                <Stack>
                  <Typography variant="body4" color="text.disabled">
                    {t("viewDocument.system_number")}
                  </Typography>
                  <Typography variant="h9_semiBold">{id}</Typography>
                </Stack>
              </Grid>
              <Grid xs={6} item>
                <Stack>
                  <Typography variant="body4" color="text.disabled">
                    {t("viewDocument.date")}
                  </Typography>
                  <Typography variant="h9_semiBold">
                    {getLocaleDateFormat(createdAt)}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid xs={4} item>
            <Stack>
              <Typography variant="body4" color="text.disabled">
                {t("viewDocument.status")}
              </Typography>
              <Typography variant="h9_semiBold">
                {t(`documentStatus.${statusForCorrespondence}`)}
              </Typography>
            </Stack>
          </Grid>
          <Grid xs={8} item>
            <Stack>
              <Typography variant="body4" color="text.disabled">
                {t("viewDocument.secrecy_grading")}
              </Typography>
              <Typography variant="h9_semiBold">
                {data.confidentially
                  ? t("viewDocument.confidential")
                  : t("viewDocument.general")}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid xs={constructionComplexes.length ? 4 : 8} item>
            <Stack>
              <Typography variant="body4" color="text.disabled">
                {t("viewDocument.registered")}
              </Typography>
              <Tooltip title={registeredByLongName} arrow>
                <Typography variant="h9_semiBold" className="ellipsis">
                  {registeredByLongName}
                </Typography>
              </Tooltip>
            </Stack>
          </Grid>
          <Grid xs={constructionComplexes.length ? 8 : 0} item>
            {constructionComplexes.length ? (
              <Stack>
                <Typography variant="body4" color="text.disabled">
                  {t("viewDocument.construction_complex_affiliation")}
                </Typography>
                <Tooltip title={complexes} arrow>
                  <div className="ellipsis">
                    <Typography variant="h9_semiBold">{complexes}</Typography>
                  </div>
                </Tooltip>
              </Stack>
            ) : null}
          </Grid>
        </Grid>
      </CardWrapper>
    </Grid>
  );

  return (
    <>
      <Grid item xs={12} md={6} mt={8} mb={4}>
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          height={36}
        >
          <Stack flexDirection="row" alignItems="center" gap={2}>
            <Typography variant="h6_semiBold" color="text.secondary">
              {t("viewDocument.document_details")}
            </Typography>

            <IconButton size="small" onClick={handleOpenFilePreviewDrawer}>
              <Icon
                name="FileSearch"
                color={theme.palette.secondary.main}
                className="cursor-pointer"
              />
            </IconButton>
          </Stack>

          <Stack flexDirection="row" alignItems="center" gap={4}>
            {queryToNavigateBack && (
              <Stack>
                <Button
                  variant="text"
                  color="secondary"
                  size="large"
                  startIcon={
                    <Icon
                      name="ArrowLeft"
                      color={theme.palette.secondary.main}
                      className="cursor-pointer"
                    />
                  }
                  onClick={navigateBack}
                >
                  {t("back_button")}
                </Button>
              </Stack>
            )}
            {renderButton}
          </Stack>
        </Stack>
      </Grid>
      <Grid container spacing={3}>
        {isOutGoingDocument ? recipient_data : sender_data}
        {isOutGoingDocument ? sender_data : recipient_data}
        {registration}
      </Grid>
      <Stack>
        <CardWrapper>
          <Typography variant="h9_semiBold" color="text.disabled">
            {t("viewDocument.document_content").toUpperCase()}
          </Typography>
          <Stack
            display="flex"
            flexDirection="row"
            gap={2}
            justifyContent="space-between"
          >
            <Stack gap={4}>
              <Stack>
                <Typography variant="body4" color="text.disabled">
                  {t("viewDocument.topic")}
                </Typography>
                <Typography variant="h9_semiBold">
                  {t(textForTopic || topicForTheCorrespondence || "")}
                </Typography>
              </Stack>
              <Stack>
                {data.contextForTheCorrespondence && (
                  <Typography variant="body4" color="text.disabled">
                    {t("viewDocument.context")}
                  </Typography>
                )}
                <Typography variant="h9_semiBold">
                  {data.contextForTheCorrespondence ? (
                    <Typography variant="h9_semiBold">
                      {data.contextForTheCorrespondence}
                    </Typography>
                  ) : (
                    <Stack height={18} />
                  )}
                </Typography>
              </Stack>
            </Stack>
            {data.confidentially && (
              <img
                src={isEnglish ? Confidential_image_en : Confidential_image_ru}
                alt="SBM"
                width={247}
                height={146}
              />
            )}
          </Stack>
        </CardWrapper>
      </Stack>

      <AdjustAgreementRouteContainer
        correspondenceId={id}
        correspondenceType={correspondenceType!}
      />

      <FilePreviewDrawer correspondenceId={id} />
      <GenerateFileDrawer />
      <SendMailContainer
        recipients={data?.recipients}
        canSendViaOrganizationEmail={data?.canSendViaOrganizationEmail}
      />
    </>
  );
};
