import { useQuery } from "@tanstack/react-query";
import { IEmployee, IEmployeeInHolding } from "@types";

import {
  fetchEmployeeById,
  fetchEmployees,
  fetchEmployeesInHolding,
} from "../../requests";

export const useGetEmployees = ({
  search,
  organizationId,
  subordinate,
  limit,
  headOfStructuralUnit,
}: {
  search?: string;
  organizationId?: number;
  subordinate?: boolean;
  limit?: number;
  headOfStructuralUnit?: boolean;
}) => {
  return useQuery<{ items: IEmployee[] }>(
    [
      "get_employees",
      search,
      organizationId,
      subordinate,
      headOfStructuralUnit,
    ],
    async () =>
      await fetchEmployees(
        search,
        organizationId,
        subordinate,
        limit,
        headOfStructuralUnit
      )
  );
};

export const useGetEmployeesInHolding = ({
  search,
  organizationId,
  headOfStructuralUnit,
  limit,
}: {
  search?: string;
  limit?: number;
  organizationId?: number;
  headOfStructuralUnit?: boolean;
}) => {
  return useQuery<{ items: IEmployeeInHolding[] }>(
    [
      "get_employees_in_holding",
      search,
      limit,
      organizationId,
      headOfStructuralUnit,
    ],
    async () =>
      await fetchEmployeesInHolding(
        search,
        limit,
        organizationId,
        headOfStructuralUnit
      )
  );
};

export const useGetEmployeeById = (id?: number) => {
  return useQuery<IEmployee>(
    ["get_employee_by_id", id],
    async () => id && (await fetchEmployeeById(id))
  );
};
