import React, { ChangeEvent, useRef } from "react";
import { useTranslation } from "react-i18next";

import { axiosService } from "app";
import { Icon } from "ui-kit";

import {
  Button,
  IconButton,
  Input,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";

import { EmailMethodContainerProps } from "../EmailMethodContainer";
import { createObjectURL } from "./helpers";

export const AttachFileForm: React.FC<EmailMethodContainerProps> = ({
  index,
  setValue,
  current,
}) => {
  const theme = useTheme();
  const { t } = useTranslation("correspondence");
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile) {
      const { data } = await axiosService({
        endpoint: "/files",
        method: "PUT",
        body: { file: selectedFile },
        headers: { "Content-Type": "multipart/form-data" },
      });
      setValue(`messages.${index}.file`, selectedFile);
      if (data.id) {
        setValue(`messages.${index}.fileId`, data.id);
      }
    }
  };

  const formatFileSize = (size: number): string => {
    if (size < 1024) return `${size} ${t("file_system.b")}`;
    else if (size < 1024 * 1024)
      return `${(size / 1024).toFixed(2)} ${t("file_system.kb")}`;
    else if (size < 1024 * 1024 * 1024)
      return `${(size / (1024 * 1024)).toFixed(2)} ${t("file_system.mb")}`;
    else
      return `${(size / (1024 * 1024 * 1024)).toFixed(2)} ${t(
        "file_system.gb"
      )}`;
  };

  const handleRemoveFile = () => {
    setValue(`messages.${index}.file`, null);
    setValue(`messages.${index}.fileId`, undefined);
  };

  const triggerFileInputChange = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
      event.preventDefault();
    }
  };

  return (
    <React.Fragment>
      {current.file ? (
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Stack direction={"row"} gap={2}>
            <a
              href={createObjectURL(current.file)}
              target="_blank"
              rel="noopener noreferrer"
              className="uploaded_file"
              style={{
                color: theme.palette.text.primary,
                textDecoration: "none",
              }}
            >
              <Typography variant="body2" fontWeight={600}>
                {current.file.name}
              </Typography>
            </a>
            <Typography
              variant="body2"
              fontWeight={600}
              color={theme.palette.text.disabled}
            >
              {formatFileSize(current.file.size)}
            </Typography>
          </Stack>
          <IconButton sx={{ cursor: "pointer" }} onClick={handleRemoveFile}>
            <Icon
              name="X"
              color={theme.palette.primary.main}
              className="cursor-pointer"
            />
          </IconButton>
        </Stack>
      ) : (
        <React.Fragment>
          <Input
            type="file"
            id="file-upload"
            onChange={handleFileChange}
            inputRef={fileInputRef}
            sx={{ display: "none" }}
          />
          <label htmlFor="file-upload">
            <Button
              fullWidth
              component="span"
              size="large"
              color="secondary"
              variant="outlined"
              onClick={triggerFileInputChange}
              startIcon={
                <Icon name="Paperclip" color={theme.palette.secondary.main} />
              }
            >
              {t("attach_file_sending")}
            </Button>
          </label>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
