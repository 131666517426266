import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useTransferForSending } from "api";
import { Modal } from "ui-kit";

import { FormControlLabel, Stack, Switch } from "@mui/material";
import { Button, Input } from "@sbm/ui-components";

export const TransferForSendingCommentPopUp = ({
  open,
  onClose,
  data,
}: {
  open: boolean;
  onClose: () => void;
  data: {
    data: {
      correspondenceId: number;
    };
  };
}) => {
  const { t } = useTranslation("correspondence");

  const [comment, setComment] = useState("");
  const [checked, setChecked] = React.useState(false);

  const { mutate: transferForSending, isLoading } =
    useTransferForSending(onClose);

  const handleTransfer = () => {
    const requestBody = {
      body: {
        correspondenceId: data.data.correspondenceId,
        commentOfActor: comment,
      },
    };
    transferForSending(requestBody);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComment("");
    setChecked(event.target.checked);
  };
  const isDisabled = useMemo(() => {
    if (!checked) {
      return false;
    } else return !comment.trim().length;
  }, [checked, comment]);

  const content = {
    title: t("transfer_for_sending_text_comment_title"),
    body: (
      <Stack gap={3}>
        <FormControlLabel
          value={checked}
          label={t("leave_comment")}
          labelPlacement="end"
          control={
            <Switch
              color="secondary"
              checked={checked}
              onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
        />

        {!checked ? null : (
          <Input
            label={t("comment")}
            multiline
            required={checked}
            maxLength={1000}
            onChange={(e) => setComment(e.target.value)}
            sx={{ my: 2 }}
          />
        )}
      </Stack>
    ),

    mainButton: (
      <Button
        autoFocus
        variant="contained"
        color="secondary"
        onClick={handleTransfer}
        loading={isLoading}
        disabled={isDisabled || isLoading}
      >
        {t("confirm")}
      </Button>
    ),
  };

  return (
    <Modal isOpen={open} onClose={onClose} content={content} updatedVersion />
  );
};
