import toast from "react-hot-toast";

import {
  abortExecution,
  acceptExecution,
  approveExecution,
  completeExecution,
  confirmExecution,
  createRequestInSBM,
  createTask,
  deleteTaskAttachedFile,
  deleteTask,
  editAttachedFile,
  editDraftTask,
  editTask,
  fetchEventForTaskTracking,
  fetchPotentialExecutors,
  fetchSupportTasks,
  fetchTaskAttachmentsById,
  fetchTaskById,
  fetchTaskLinksById,
  fetchTasks,
  fetchTasksForCalendar,
  rejectExecution,
  sendForRevision,
  uploadFile,
  uploadLinks,
  viewTask,
  sendToArchive,
  fetchTasksForCards,
  fetchTaskTrackerScheme,
  addFavoriteTask,
  removeFavoriteTask,
  fetchDnDCards,
  registerSupportTask,
  rejectTechSupportTask,
  clarifyTechSupportTask,
  commentTechSupportTask,
  editFeedbackRequestTask,
} from "api";
import { useAppDispatch } from "app";
import { AxiosError } from "axios";

import { getFiltersQueryCacheKey, SnackbarMessages } from "@sbm/fe-utils";
import { getAPIErrorMessage } from "@sbm/fe-utils";
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  IAcceptExecution,
  IAttachesTaskLinkBody,
  IAttachmentFile,
  IAttachmentLink,
  IEditDraftTaskBody,
  IEditFeedbackRequestTaskBody,
  IEditTaskBody,
  IExecutionTrackerScheme,
  IFavoriteTask,
  IMeta,
  IRegisterSupportRequestPayload,
  IRejectExecution,
  ISupportTask,
  ITask,
  ITaskById,
  ITaskForCalendar,
  ITaskForCard,
  IViewTask,
  PotentialExecutor,
  TasksViewEnum,
  TExecutionTrackerNode,
} from "@types";

export const useGetTasks = (
  type: "incoming" | "outgoing",
  sortBy = "",
  search = "",
  filter: (string | undefined)[] = [],
  path: TasksViewEnum,
  options: Record<string, unknown>,
  employeeId?: number,
  documentId?: number
) => {
  const queryKeyFilter = getFiltersQueryCacheKey(filter);

  return useInfiniteQuery<{ items: ITask[]; meta: IMeta }>(
    [
      "get_tasks",
      sortBy,
      search,
      type,
      path,
      documentId,
      ...queryKeyFilter,
      ...(employeeId ? [employeeId] : []),
    ],
    (params) =>
      fetchTasks(
        params,
        type,
        search,
        sortBy,
        filter,
        path,
        employeeId,
        documentId
      ),
    {
      ...options,
      retry: false,
      getPreviousPageParam: (params) => {
        const prevPage = params.meta.currentPage - 1;
        return prevPage <= 0 ? 0 : prevPage;
      },
      getNextPageParam: (params) => {
        if (!params?.meta) return 1;

        if (params.meta.currentPage < params.meta.totalPages) {
          return params.meta.currentPage + 1;
        }
      },
    }
  );
};

export const useGetSupportTasks = (
  sortBy = "",
  search = "",
  filter: (string | undefined)[] = []
) => {
  const queryKeyFilter = getFiltersQueryCacheKey(filter);

  return useInfiniteQuery<{ items: ISupportTask[]; meta: IMeta }>(
    ["get_support_tasks", sortBy, search, ...queryKeyFilter],
    (params) => fetchSupportTasks(params, search, sortBy, filter),
    {
      retry: false,
      getPreviousPageParam: (params) => {
        const prevPage = params.meta.currentPage - 1;
        return prevPage <= 0 ? 0 : prevPage;
      },
      getNextPageParam: (params) => {
        if (!params?.meta) return 1;

        if (params.meta.currentPage < params.meta.totalPages) {
          return params.meta.currentPage + 1;
        }
      },
    }
  );
};

export const useGetTasksForCalendar = (
  type: "incoming" | "outgoing",
  filter: (string | undefined)[] = [],
  includeOverdueFilter?: boolean,
  options?: Record<string, unknown>,
  employeeId?: number
) => {
  const queryKeyFilter = getFiltersQueryCacheKey(filter);

  return useQuery<{ items: ITaskForCalendar[]; meta: IMeta }>(
    [
      "get_tasks_for_calendar",
      type,
      includeOverdueFilter,
      ...queryKeyFilter,
      ...(employeeId ? [employeeId] : []),
    ],
    () => fetchTasksForCalendar(type, filter, includeOverdueFilter, employeeId),
    {
      ...options,
      retry: false,
    }
  );
};

export const useGetTasksForCards = (type: "incoming" | "outgoing") => {
  return useQuery<ITaskForCard[]>(
    ["get_tasks_for_cards", type],
    () => fetchTasksForCards(type),
    {
      retry: false,
    }
  );
};

export const useChangeFavoriteTaskCardsPosition = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (body: { favoriteTasksIds: number[] }) => {
      await fetchDnDCards(body.favoriteTasksIds);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["get_tasks_for_cards"]);
        onSuccess && onSuccess();
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
      },
    }
  );
};

export const useGetTaskById = (
  id: string | undefined,
  options: Record<string, unknown>,
  supportTask?: boolean
) => {
  return useQuery<ITaskById>(
    ["get_task_by_id", id],
    async () => await fetchTaskById(id, supportTask),
    {
      ...options,
      retry: false,
    }
  );
};

export const useGetTaskAttachmentsById = (id: number | undefined) => {
  return useQuery<{ items: IAttachmentFile[] }>(
    ["get_task_attachment_by_id", id],
    async () => await fetchTaskAttachmentsById(id)
  );
};

export const useGetTaskLinksById = (id: number | undefined) => {
  return useQuery<{ items: IAttachmentLink[] }>(
    ["get_task_links_by_id", id],
    async () => await fetchTaskLinksById(id)
  );
};

export const useGetPotentialExecutors = () => {
  return useQuery<PotentialExecutor[] | undefined>(
    ["get_potential_executors"],
    fetchPotentialExecutors
  );
};

export const useGetEventForTaskTracking = (
  taskId: number,
  rootTaskId: number
) => {
  return useQuery<TExecutionTrackerNode[] | undefined>(
    ["event_for_task_tracking", taskId, rootTaskId],
    async () => await fetchEventForTaskTracking(taskId, rootTaskId)
  );
};

export const useAcceptExecution = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (body: IAcceptExecution) => {
      await acceptExecution(body);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["get_task_by_id"]);
        toast.success(SnackbarMessages.success);
        onSuccess && onSuccess();
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
      },
    }
  );
};

export const useUploadFileToTaskMutation = (
  onSuccess: () => void,
  onError: () => void
) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (body: { requestBody: FormData; taskId: number }) => {
      await uploadFile(body);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["get_task_attachment_by_id"]);
        onSuccess();
        toast.success(SnackbarMessages.success);
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        onError();
        toast.error(errorMsg);
      },
    }
  );
};

export const useUploadLinkToTaskMutation = (
  onSuccess: () => void,
  onError: () => void
) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (body: { requestBody: IAttachesTaskLinkBody; taskId: number }) => {
      await uploadLinks(body);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["get_task_links_by_id"]);
        onSuccess();
        toast.success(SnackbarMessages.success);
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        onError();
        toast.error(errorMsg);
      },
    }
  );
};

export const useApproveExecution = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (taskId: number) => {
      await approveExecution(taskId);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["get_task_by_id"]);
        toast.success(SnackbarMessages.success);
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
      },
    }
  );
};

export const useSendToArchiveMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (taskId: number) => {
      await sendToArchive(taskId);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["get_task_by_id"]);
        await queryClient.invalidateQueries(["get_tasks"]);
        toast.success(SnackbarMessages.success);
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
      },
    }
  );
};

export const useCreateTask = (onSuccess: () => void, onError: () => void) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    async (body: FormData) => {
      await createTask(body);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["get_tasks"]);
        await queryClient.invalidateQueries(["get_task_by_id"]);
        onSuccess();
        toast.success(SnackbarMessages.success);
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
        onError();
      },
    }
  );
};

export const useCreateRequestInSBM = (
  onSuccess: () => void,
  onError: () => void
) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (body: FormData) => {
      await createRequestInSBM(body);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["get_support_tasks"]);
        await queryClient.invalidateQueries(["get_task_by_id"]);
        onSuccess();
        toast.success(SnackbarMessages.success);
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
        onError();
      },
    }
  );
};

export const useEditTask = (onSuccess: () => void) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (body: { data: IEditTaskBody; taskId: number }) => {
      await editTask(body.data, body.taskId);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["get_task_by_id"]);
        toast.success(SnackbarMessages.success);
        onSuccess();
      },
    }
  );
};

export const useEditAttachedFileMutation = (
  onSuccess: () => void,
  onError: () => void
) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (body: { requestBody: FormData; taskId: number; fileId: number }) => {
      await editAttachedFile(body.requestBody, body.taskId, body.fileId);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["get_task_attachment_by_id"]);
        toast.success(SnackbarMessages.success);
        onSuccess();
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
        onError();
      },
    }
  );
};

export const useEditDraftTask = (onSuccess: () => void) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (body: { data: IEditDraftTaskBody; taskId: number }) => {
      await editDraftTask(body.data, body.taskId);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["get_task_by_id"]);
        toast.success(SnackbarMessages.success);
        onSuccess();
      },
    }
  );
};

export const useEditFeedbackRequestTask = (onSuccess: () => void) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (body: { data: IEditFeedbackRequestTaskBody; taskId: number }) => {
      await editFeedbackRequestTask(body.data, body.taskId);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["get_task_by_id"]);
        toast.success(SnackbarMessages.success);
        onSuccess();
      },
    }
  );
};

export const useViewTaskMutation = () => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    async (body: IViewTask) => {
      await viewTask(body);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["get_tasks"]);
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
      },
    }
  );
};

export const useMarkedTaskAsFavoriteMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (body: IFavoriteTask) => {
      await addFavoriteTask(body);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["get_tasks_for_cards"]);
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
      },
    }
  );
};

export const useRemoveTaskAsFavoriteMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (body: IFavoriteTask) => {
      await removeFavoriteTask(body);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["get_tasks_for_cards"]);
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
      },
    }
  );
};

export const useRejectExecutionMutation = (onSuccess: () => void) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    async (body: IRejectExecution) => {
      await rejectExecution(body);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["get_task_by_id"]);
        onSuccess();
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
      },
    }
  );
};

export const useSendForRevisionMutation = (onSuccess: () => void) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (body: IRejectExecution) => {
      await sendForRevision(body);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["get_task_by_id"]);
        onSuccess();
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
      },
    }
  );
};

export const useRejectTechSupportRequestMutation = (onSuccess: () => void) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (body: IRejectExecution) => {
      await rejectTechSupportTask(body);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["get_task_by_id"]);
        toast.success(SnackbarMessages.success);
        onSuccess();
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
      },
    }
  );
};

export const useCommentTechSupportRequestMutation = (onSuccess: () => void) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (body: IRejectExecution) => {
      await commentTechSupportTask(body);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["get_task_by_id"]);
        toast.success(SnackbarMessages.success);
        onSuccess();
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
      },
    }
  );
};

export const useClarifyTechSupportRequestMutation = (onSuccess: () => void) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (body: IRejectExecution) => {
      await clarifyTechSupportTask(body);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["get_task_by_id"]);
        toast.success(SnackbarMessages.success);
        onSuccess();
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
      },
    }
  );
};

export const useAbortExecutionMutation = (onSuccess: () => void) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (body: IRejectExecution) => {
      await abortExecution(body);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["get_task_by_id"]);
        onSuccess();
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
      },
    }
  );
};

export const useCompleteExecutionMutation = (onSuccess: () => void) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    async (body: IRejectExecution) => {
      await completeExecution(body);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["get_task_by_id"]);
        onSuccess();
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
      },
    }
  );
};

export const useConfirmExecutionMutation = (onSuccess: () => void) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    async (body: IRejectExecution) => {
      await confirmExecution(body);
    },
    {
      onSettled: () => {
        onSuccess();
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(["get_task_by_id"]);
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
      },
    }
  );
};

export const useDeleteTaskMutation = (onSuccess: () => void) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (id: number) => {
      await deleteTask(id);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["get_task_by_id"]);
        toast.success(SnackbarMessages.success);
        onSuccess();
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
      },
    }
  );
};

export const useDeleteFeedbackRequestMutation = (onSuccess: () => void) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (id: number) => {
      await deleteTask(id);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["get_task_by_id"]);
        toast.success(SnackbarMessages.success);
        onSuccess();
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
      },
    }
  );
};

export const useDeleteTaskFileMutation = (onSuccess: () => void) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (body: { taskId: number; fileId: number }) => {
      if (!body.taskId || !body.fileId) return;
      await deleteTaskAttachedFile(body.taskId, body.fileId);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["get_task_attachment_by_id"]);
        toast.success(SnackbarMessages.success);
        onSuccess();
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
      },
    }
  );
};

export const useGetTaskTrackerScheme = (rootTaskId: number) => {
  return useQuery<IExecutionTrackerScheme[]>(
    ["get_tasks_tracker_scheme", rootTaskId],
    async () => await fetchTaskTrackerScheme(rootTaskId),
    {
      retry: false,
    }
  );
};

export const useRegisterSupportTaskMutation = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (body: IRegisterSupportRequestPayload) => {
      await registerSupportTask(body);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["get_task_by_id"]);
        toast.success(SnackbarMessages.success);
        onSuccess && onSuccess();
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
      },
    }
  );
};
