import React, { useMemo } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  axiosService,
  getUserEmployeeOrganizationsAndPositions,
  getUserExternalSubstitutionOrganizationsAndPositions,
  useAppSelector,
} from "app";

import { Card, CardContent, Stack, Typography } from "@mui/material";
import { getPersonalDataName, getUniqueListBy } from "@sbm/fe-utils";
import { FormAutocomplete, FormSelect } from "@sbm/ui-components";
import {
  IEmployeeInHolding,
  IOrganization,
  StructuralUnitTypesEnum,
  TypeOfCorrespondenceInHoldingEnum,
} from "@types";

import { DetailsStepProps } from "../DetailsStep";

const isViewMode = false;

export const DetailsStepSender: React.FC<
  Pick<
    DetailsStepProps,
    "control" | "setValue" | "register" | "errors" | "isEditMode"
  >
> = ({ control, setValue, isEditMode }) => {
  const { t } = useTranslation("correspondence");

  const { userProfile } = useAppSelector((state) => state.auth);

  const { senderOrganizationId, sender } = useWatch({
    control,
  });

  const [inputOrganizationValue, setInputOrganizationValue] =
    React.useState("");
  const [inputEmployeeValue, setInputEmployeeValue] = React.useState("");

  const [organizationOptions, setOrganizationOptions] = React.useState<
    { title: string; id?: number; belongingToTheEvGroup: boolean }[]
  >([]);

  const [employeeOptions, setEmployeeOptions] = React.useState<
    {
      title: string;
      id?: number;
      position?: string;
      typeOfRecipient?: TypeOfCorrespondenceInHoldingEnum;
    }[]
  >([]);

  const userEmployeeOrganizationsAndPositions =
    getUserEmployeeOrganizationsAndPositions(userProfile!.personalData);

  const userExternalSubstitutionOrganizationsAndPositions =
    getUserExternalSubstitutionOrganizationsAndPositions(
      userProfile!.personalData
    );

  const uniqueEmployeeAndExternalSubList = getUniqueListBy(
    [
      ...userEmployeeOrganizationsAndPositions,
      ...userExternalSubstitutionOrganizationsAndPositions,
    ],
    "id"
  ).map((i) => i.id);

  const senderPositionOptions = useMemo(() => {
    if (employeeOptions?.length > 0) {
      return employeeOptions.map((option) => {
        return {
          option: option.position,
          value: option.position,
        };
      });
    }

    return [];
  }, [employeeOptions]);

  // Default values
  const getOrganizationDefaultValue = React.useCallback(
    (id?: number) => {
      if (!id || !organizationOptions.length) return "";
      const organization = organizationOptions.find((i) => i.id === id);
      if (!organization) return "";
      return organization.title;
    },
    [organizationOptions]
  );

  const getEmployeeDefaultValue = React.useCallback(
    (id?: number, type?: TypeOfCorrespondenceInHoldingEnum) => {
      if (!id || !employeeOptions.length) return "";

      const option = employeeOptions.find((i) => {
        return i.id === id && i.typeOfRecipient === type;
      });

      if (!option) return "";

      return option.title;
    },
    [employeeOptions]
  );

  const fetchEmployees = React.useCallback(
    async (search: string) => {
      if (!senderOrganizationId) return [];

      const { data } = await axiosService({
        endpoint: `employees/in-holding`,
        body: {
          search,
          "filter.organizationId": senderOrganizationId,
          "filter.structuralUnitType": [
            StructuralUnitTypesEnum.mainGoverningBody,
            StructuralUnitTypesEnum.goveringBody,
          ],
        },
      });

      const result = data
        ? (data.items as IEmployeeInHolding[])
            .map((i) => ({
              title: getPersonalDataName(i.personalData, true),
              id: i.externalSubstitutionId || i.employeeId,
              typeOfRecipient: i.externalSubstitutionId
                ? TypeOfCorrespondenceInHoldingEnum.externalSubstitution
                : TypeOfCorrespondenceInHoldingEnum.employee,
              position: i.structuralUnit?.positionName || "",
              displayLabel: (
                <Stack>
                  <Typography variant="body2" fontWeight={600}>
                    {getPersonalDataName(i.personalData, true)}
                  </Typography>
                  <Typography variant="body3" color="text.secondary">
                    {i.structuralUnit?.positionName}
                  </Typography>
                </Stack>
              ),
            }))
            .filter((i) => Boolean(i.title))
        : [];

      // ToDo: Fix this part
      // if (isEditMode && senderEmployeeId) {
      //   const isInDefaultGetList = !result.find(
      //     (r) => r.id !== senderEmployeeId
      //   );
      //
      //   if (!isInDefaultGetList) {
      //     const { data: employee } = await axiosService({
      //       endpoint: `employees/${senderEmployeeId}`,
      //     });
      //
      //     if (employee) {
      //       result.push({
      //         title: getPersonalDataName(employee.personalData, true),
      //         id: employee.id,
      //         position: employee.staffUnit?.namePosition,
      //       });
      //     }
      //   }
      // }

      return result;
    },
    [senderOrganizationId]
  );

  const fetchOrganizations = React.useCallback(
    async (search: string) => {
      const { data } = await axiosService({
        endpoint: `organizations`,
        body: {
          search,
          "filter.organizationIds": uniqueEmployeeAndExternalSubList,
          "filter.belongingToTheEvGroup": true,
        },
      });

      const options = data
        ? (data.items as IOrganization[])
            .map((i) => ({
              title: i.briefTextOrganizationName || i.shortName,
              id: i.id,
              belongingToTheEvGroup: i.belongingToTheEvGroup,
            }))
            .filter((i) => Boolean(i.title))
        : [];

      if (senderOrganizationId && isViewMode) {
        const exists = options.findIndex((i) => i.id === senderOrganizationId);

        if (exists === -1) {
          const { data } = await axiosService({
            endpoint: `organizations/${senderOrganizationId}`,
          });

          const optionToAdd = {
            title: data.briefTextOrganizationName,
            id: data.id,
            belongingToTheEvGroup: data.belongingToTheEvGroup,
          };

          options.push(optionToAdd);
        }
      }
      return options || [];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [senderOrganizationId, JSON.stringify(uniqueEmployeeAndExternalSubList)]
  );

  const handleEmployeeInputChange = (val: string, reason: string) => {
    setInputEmployeeValue(val);

    if (reason === "input") return;
    // Delete action
    if (reason === "clear") {
      setValue("sender", undefined, { shouldDirty: true });
      setValue("senderEmployeePosition", undefined, { shouldDirty: true });
      return;
    }
    // Action is select from the list, should be stored under <personalDataId>
    if (reason === "reset") {
      const option = employeeOptions.find((i) => i.title === val);
      if (!option) return;

      setValue("senderEmployeePosition", option.position);

      setValue("sender.id", option.id, { shouldDirty: true });
      setValue("sender.type", option.typeOfRecipient, { shouldDirty: true });
    }
  };

  const handleOrganizationInputChange = (val: string, reason: string) => {
    setValue("topicForTheCorrespondence", undefined);
    setValue("textForTopicId", undefined);
    setValue("responsible", undefined, { shouldDirty: true });

    setInputOrganizationValue(val);

    if (reason === "input") return "";
    // Delete action
    if (reason === "clear") {
      setValue("senderOrganizationId", undefined);
      setValue("sender", undefined);

      return;
    }
    // Action is select from the list, should be stored under <personalDataId>
    if (reason === "reset") {
      const option = organizationOptions.find((i) => i.title === val);
      if (!option) return;

      setValue("senderOrganizationId", option.id);
    }
  };

  return (
    <Card>
      <CardContent sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
        <Typography variant="h9_semiBold" color="text.disabled" sx={{ mb: 2 }}>
          {t("createLetterDrawer.senders_area.title").toUpperCase()}
        </Typography>

        <FormAutocomplete
          required
          disabled={isEditMode}
          freeSolo={false}
          inputValue={inputOrganizationValue}
          label={t("createLetterDrawer.senders_area.organization")}
          defaultSelected={getOrganizationDefaultValue(senderOrganizationId)}
          fetchData={fetchOrganizations}
          onFetchDataSuccess={setOrganizationOptions}
          onInputChange={handleOrganizationInputChange}
        />

        <FormAutocomplete
          required
          disabled={!senderOrganizationId}
          freeSolo={false}
          inputValue={inputEmployeeValue}
          label={t("createLetterDrawer.senders_area.fullName")}
          defaultSelected={getEmployeeDefaultValue(sender?.id, sender?.type)}
          fetchData={fetchEmployees}
          onFetchDataSuccess={setEmployeeOptions}
          onInputChange={handleEmployeeInputChange}
        />

        <FormSelect
          disabled
          label={t("createLetterDrawer.senders_area.position")}
          name="senderEmployeePosition"
          control={control}
          values={senderPositionOptions}
        />
      </CardContent>
    </Card>
  );
};
