import { ILetterMessages, MethodOfSendingEnum } from "@types";

export const createRequestBody = (
  messages: ILetterMessages[]
): (Partial<ILetterMessages> | null)[] => {
  let result: Partial<ILetterMessages>;
  return messages.map((item) => {
    switch (item.methodOfSending) {
      case MethodOfSendingEnum.byEmail:
        result = {
          sendDate: item.sendDate,
          senderEmailId: item.senderEmailId,
          recipientEmailId: item.recipientEmailId,
          fileId: item.fileId,
          methodOfSending: item.methodOfSending,
          counterpartyId: item.counterpartyId,
        };
        return filterMessageFields(
          ["sendDate", "senderEmailId", "recipientEmailId", "methodOfSending"],
          result
        );
      case MethodOfSendingEnum.byRussianPost:
        result = {
          sendDate: item.sendDate,
          note: item.note,
          fileId: item.fileId,
          methodOfSending: item.methodOfSending,
          counterpartyId: item.counterpartyId,
        };
        return filterMessageFields(["sendDate", "methodOfSending"], result);
      case MethodOfSendingEnum.viaCourierDeliveryService:
        result = {
          sendDate: item.sendDate,
          courierServiceName: item.courierServiceName,
          note: item.note,
          fileId: item.fileId,
          methodOfSending: item.methodOfSending,
          counterpartyId: item.counterpartyId,
        };
        return filterMessageFields(
          ["sendDate", "courierServiceName", "methodOfSending"],
          result
        );
      case MethodOfSendingEnum.byARepresentativeOfTheSendingOrganisation:
        result = {
          sendDate: item.sendDate,
          note: item.note,
          fileId: item.fileId,
          methodOfSending: item.methodOfSending,
          counterpartyId: item.counterpartyId,
        };
        return filterMessageFields(
          ["sendDate", "note", "methodOfSending"],
          result
        );
      default:
        return null;
    }
  });
};

const filterMessageFields = (
  requiredFields: (keyof ILetterMessages)[],
  result: Partial<ILetterMessages>
): Partial<ILetterMessages> | null => {
  const filteredResult: Record<string, unknown> = {};
  const allFieldsExist = requiredFields.every(
    (field) => field in result && Boolean(result[field])
  );

  if (!allFieldsExist) {
    return null;
  }

  Object.keys(result).forEach((key) => {
    if (result[key as keyof ILetterMessages]) {
      filteredResult[key] = result[key as keyof ILetterMessages];
    }
  });

  return filteredResult;
};
