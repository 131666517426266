import React from "react";
import { Control, UseFormRegister, UseFormSetValue } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ToggleSection } from "ui-kit";

import { Stack, Typography, useTheme } from "@mui/material";
import { ILetterMessages, IMailData, MethodOfSendingEnum } from "@types";

import { CourierMethodContainer } from "../CourierMethodContainer";
import { EmailMethodContainer } from "../EmailMethodContainer";
import { RussianPostMethodContainer } from "../RussianPostMethodContainer";
import { ServiceMethodContainer } from "../ServiceMethodContainer";

export interface SendingStepProps {
  setValue: UseFormSetValue<IMailData>;
  register: UseFormRegister<IMailData>;
  messages?: ILetterMessages[];
  control: Control<IMailData>;
}

export const SendingStep: React.FC<SendingStepProps> = ({
  setValue,
  register,
  messages,
}) => {
  const theme = useTheme();
  const { t } = useTranslation("correspondence");

  if (!messages?.length) {
    return <></>;
  }

  const renderMeethodComponent = (
    methodOfSending: MethodOfSendingEnum | undefined,
    message: ILetterMessages,
    index: number
  ) => {
    switch (methodOfSending) {
      case MethodOfSendingEnum.byEmail:
        return (
          <EmailMethodContainer
            setValue={setValue}
            current={message}
            index={index}
          />
        );
      case MethodOfSendingEnum.byRussianPost:
        return (
          <RussianPostMethodContainer
            register={register}
            setValue={setValue}
            current={message}
            index={index}
          />
        );
      case MethodOfSendingEnum.viaCourierDeliveryService:
        return (
          <ServiceMethodContainer
            register={register}
            setValue={setValue}
            current={message}
            index={index}
          />
        );
      case MethodOfSendingEnum.byARepresentativeOfTheSendingOrganisation:
        return (
          <CourierMethodContainer
            register={register}
            setValue={setValue}
            current={message}
            index={index}
          />
        );
      default:
        return null;
    }
  };

  return (
    <React.Fragment>
      {messages.map((message, index) => {
        return (
          <Stack key={index} mt={8}>
            <Stack mb={6} direction={"column"}>
              <Typography variant={"body1"} fontWeight={700}>
                {message.personalDataLongName}
              </Typography>
              <Typography color={theme.palette.text.secondary} fontWeight={600}>
                {message.briefTextOrganizationName}
              </Typography>
            </Stack>
            {message.methodOfSystem && message.sendFromSystem && (
              <ToggleSection
                title={t("send_from_system").toUpperCase()}
                hasDivider
                subtitle={t(message.methodOfSystem || "")}
              >
                <></>
              </ToggleSection>
            )}
            {message.methodOfSending && message.sendFromService && (
              <ToggleSection
                title={t("via_external_services").toUpperCase()}
                hasDivider
                subtitle={t(message.methodOfSending || "")}
              >
                {renderMeethodComponent(
                  message.methodOfSending,
                  message,
                  index
                )}
              </ToggleSection>
            )}
          </Stack>
        );
      })}
    </React.Fragment>
  );
};
