import {
  IDocumentById,
  OutgoingDocumentEntity,
  TOutgoingDocumentRecipientEntity,
  TypeOfCorrespondenceEnum,
  TypeOfCorrespondenceInHoldingEnum,
} from "@types";

export const createRequestBody = (body: OutgoingDocumentEntity) => {
  const formData = new FormData();

  const keys = Object.keys(body) as (keyof OutgoingDocumentEntity)[];
  keys.forEach((key) => {
    if (
      key !== "files" &&
      key !== "links" &&
      key !== "constructionComplexes" &&
      key !== "typeOfAttachment" &&
      key !== "fileStatusAttachment" &&
      key !== "senderOrganizationId" &&
      key !== "recipients" &&
      key !== "sender" &&
      key !== "responsible"
    ) {
      formData.append(key, body[key] as string);
    }
  });

  if (body.responsible) {
    formData.append("responsible[id]", String(body.responsible.id));
    formData.append("responsible[type]", String(body.responsible.type));
  }

  if (body.sender) {
    formData.append("sender[id]", String(body.sender.id));
    formData.append("sender[type]", String(body.sender.type));
  }

  if (body.files?.length) {
    body.files?.forEach((file) => {
      formData.append("files", file);
    });
  }

  if (body.links?.length) {
    body.links?.forEach((link, index) => {
      formData.append(`links[${index}]`, link.id.toString());
    });
  }

  if (body.typeOfAttachment?.length) {
    body.typeOfAttachment?.forEach((typeOfAttachment, index) => {
      formData.append(
        `typeOfAttachment[${index}]`,
        typeOfAttachment.toString()
      );
    });
  }
  if (body.fileStatusAttachment?.length) {
    body.fileStatusAttachment?.forEach((fileStatusAttachment, index) => {
      formData.append(
        `fileStatusAttachment[${index}]`,
        fileStatusAttachment.toString()
      );
    });
  }

  if (body.constructionComplexes?.length) {
    body.constructionComplexes?.forEach((complex, index) => {
      formData.append(`constructionComplexes[${index}]`, complex.toString());
    });
  }

  if (body.recipients?.length) {
    body.recipients?.forEach((recipient, index) => {
      if (recipient.id && recipient.type) {
        formData.append(`recipients[${index}][type]`, recipient.type);
        formData.append(`recipients[${index}][id]`, recipient.id.toString());
      }
    });
  }

  return formData;
};

export const isNextButtonActive = (
  fields: OutgoingDocumentEntity,
  isEmployeeAndExternalSubInSameOrganization?: boolean,
  textForTopicId?: number,
  topicForTheCorrespondence?: string,
  isEditMode?: boolean
) => {
  const REQUIRED_FIELDS: (keyof OutgoingDocumentEntity)[] = [
    "correspondenceSubtype",
    "sender",
    "senderOrganizationId",
    "recipients",
    "responsible",
  ];

  if (fields.replyToOutgoingLetter) {
    REQUIRED_FIELDS.push("initiatingCorrespondenceId");
  }

  if (isEmployeeAndExternalSubInSameOrganization && !isEditMode) {
    REQUIRED_FIELDS.push("creatorPosition");
  }

  if (!textForTopicId && !topicForTheCorrespondence) {
    return false;
  }

  return REQUIRED_FIELDS.every((key) => {
    if (key === "recipients") {
      return fields[key].every((recipient) => {
        return recipient.id && recipient.type;
      });
    }

    return fields[key] || fields[key] === false;
  });
};

export const getDefaultDataForEdit = (
  data?: IDocumentById
): OutgoingDocumentEntity | null => {
  if (!data) return null;

  return {
    confidentially: data.confidentially,
    constructionComplexes: data.constructionComplexes.map((i) => i.id),
    contextForTheCorrespondence: data.contextForTheCorrespondence,
    controlTheResponse: data.controlTheResponse,
    correspondenceSubtype: data.correspondenceSubtype,
    expectedDateOfTheResponse: data.expectedDateOfTheResponse
      ? new Date(data.expectedDateOfTheResponse)
      : undefined,
    id: data.id,
    initiatingCorrespondenceId: data.initiatingCorrespondenceId,
    initiatingDocumentOutTheSystem: data.initiatingDocumentOutTheSystem,
    initiatingDocumentRegistrationDate: data.initiatingCorrespondenceDate
      ? new Date(data.initiatingCorrespondenceDate)
      : undefined,
    recipients: (data.recipients.map((rec) => ({
      legalEntity: rec.type !== TypeOfCorrespondenceEnum.individual,
      id: rec.id,
      recipientEmployeePosition: rec.position,
      recipientOrganizationId: rec.organizationId,
      type: rec.type,
    })) || []) as TOutgoingDocumentRecipientEntity[],
    replyToOutgoingLetter: !!data.initiatingCorrespondenceId,
    selectConstructionComplexes: data.constructionComplexes.length > 0,
    sender: data.sender
      ? {
          id: data.sender.id!,
          type: data.sender.type as TypeOfCorrespondenceInHoldingEnum,
        }
      : undefined,
    senderEmployeePosition: data.sender.position,
    senderOrganizationId: data.sender.organizationId,
    textForTopicId: data.textForTopicId,
    topicForTheCorrespondence: data.topicForTheCorrespondence,
    responsible: data.responsible
      ? { id: data.responsible.id, type: data.responsible.type }
      : undefined,
  };
};
