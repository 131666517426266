import {
  DeliveryMethodEnum,
  IDocumentById,
  IncomingDocumentEntity,
  TypeOfCorrespondenceEnum,
} from "@types";

export const createRequestBody = (body: IncomingDocumentEntity) => {
  const formData = new FormData();

  const keys = Object.keys(body) as (keyof IncomingDocumentEntity)[];
  keys.forEach((key) => {
    if (
      key !== "files" &&
      key !== "links" &&
      key !== "constructionComplexes" &&
      key !== "typeOfAttachment" &&
      key !== "legalEntity" &&
      key !== "senderOrganizationId" &&
      key !== "recipient" &&
      key !== "recipientOrganizationId" &&
      key !== "sender"
    ) {
      formData.append(key, body[key] as string);
    }
  });

  if (body.sender) {
    formData.append("sender[id]", String(body.sender.id));
    formData.append("sender[type]", String(body.sender.type));
  }

  if (body.recipient) {
    formData.append("recipient[id]", String(body.recipient.id));
    formData.append("recipient[type]", String(body.recipient.type));
  }

  if (body.files?.length) {
    body.files?.forEach((file) => {
      formData.append("files", file);
    });
  }

  if (body.links?.length) {
    body.links?.forEach((link, index) => {
      formData.append(`links[${index}]`, link.id.toString());
    });
  }

  if (body.typeOfAttachment?.length) {
    body.typeOfAttachment?.forEach((typeOfAttachment, index) => {
      formData.append(
        `typeOfAttachment[${index}]`,
        typeOfAttachment.toString()
      );
    });
  }

  if (body.constructionComplexes?.length) {
    body.constructionComplexes?.forEach((complex, index) => {
      formData.append(`constructionComplexes[${index}]`, complex.toString());
    });
  }

  return formData;
};

export const isNextButtonActive = (
  fields: IncomingDocumentEntity,
  isEmployeeAndExternalSubInSameOrganization?: boolean,
  textForTopicId?: number,
  topicForTheCorrespondence?: string,
  isEditMode?: boolean
) => {
  let REQUIRED_FIELDS: (keyof IncomingDocumentEntity)[] = [
    "regNumberForCounterparty",
    "correspondenceSubtype",
    "dateOfRegistration",
    "senderOrganizationId",
    "sender",
    "recipient",
    "recipientOrganizationId",
    "deliveryMethod",
    "storageOrderForOriginal",
  ];

  if (fields.replyToOutgoingLetter) {
    REQUIRED_FIELDS.push("initiatingCorrespondenceId");
  }

  if (fields.sender?.type === TypeOfCorrespondenceEnum.individual) {
    REQUIRED_FIELDS = REQUIRED_FIELDS.filter(
      (field) => field !== "senderOrganizationId"
    );
  }

  if (fields.deliveryMethod === DeliveryMethodEnum.byEmail) {
    REQUIRED_FIELDS = REQUIRED_FIELDS.filter(
      (i) => i !== "storageOrderForOriginal"
    );
  }

  if (isEmployeeAndExternalSubInSameOrganization && !isEditMode) {
    REQUIRED_FIELDS.push("creatorPosition");
  }

  if (!textForTopicId && !topicForTheCorrespondence) {
    return false;
  }

  return REQUIRED_FIELDS.every((key) => {
    return fields[key] || fields[key] === false;
  });
};

export const getDefaultDataForEdit = (
  data?: IDocumentById
): IncomingDocumentEntity | null => {
  if (!data) return null;

  return {
    confidentially: data.confidentially,
    constructionComplexes: data.constructionComplexes.map((i) => i.id),
    contextForTheCorrespondence: data.contextForTheCorrespondence,
    correspondenceSubtype: data.correspondenceSubtype,
    dateOfRegistration: data.dateOfRegistration
      ? new Date(data.dateOfRegistration)
      : undefined,
    deliveryMethod: data.deliveryMethod,
    emailId: data.emailId,
    sender: data.sender
      ? {
          id: data.sender.id!,
          type: data.sender.type as TypeOfCorrespondenceEnum,
        }
      : undefined,
    recipient: data.recipients?.[0]
      ? {
          id: data.recipients[0].id,
          type: data.recipients[0].type,
        }
      : undefined,
    id: data.id,
    initiatingCorrespondenceId: data.initiatingCorrespondenceId,
    initiatingDocumentOutTheSystem: data.initiatingDocumentOutTheSystem,
    initiatingDocumentRegistrationDate: data.initiatingCorrespondenceDate
      ? new Date(data.initiatingCorrespondenceDate)
      : undefined,
    legalEntity: data.sender.legalEntity,
    mainActorEmployeePosition: data.recipients[0]?.position,
    recipientOrganizationId: data.recipients[0]?.organizationId,
    regNumberForCounterparty: data.numberAtCounterparty,
    replyToOutgoingLetter: !!data.initiatingCorrespondenceId,
    selectConstructionComplexes: data.constructionComplexes.length > 0,
    senderEmployeePosition: data.sender.position,
    senderOrganizationId: data.sender.organizationId,
    storageOrderForOriginal: data.storageOrderForOriginal,
    textForTopicId: data.textForTopicId,
    topicForTheCorrespondence: data.topicForTheCorrespondence,
  };
};
